import React, { useState, useEffect, Fragment } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Spinner
} from 'reactstrap';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './AdminDashboard.css';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { VacationDays } from 'Services/vacationdays.service';
import { UsersTimeOff } from 'Services/usersTimeoff.service';
import { fetchAttendanceRecords } from "Services/attendance.service";
import { fetchDocuments } from 'Services/managedocs.service';
import { fetchshifts } from 'Services/shifts.service';

const HrmsAdminDashboard = () => {
  // **Loading States**
  const [loadingYetToPunchIn, setLoadingYetToPunchIn] = useState(true);
  const [loadingAbsent, setLoadingAbsent] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [loadingBirthdays, setLoadingBirthdays] = useState(true);
  const [loadingLatePunch, setLoadingLatePunch] = useState(true);
  const [loadingProbation, setLoadingProbation] = useState(true);
  const [probationEnding, setProbationEnding] = useState([]);
  const [latePunches, setLatePunches] = useState([]);
  const [yetToPunchIn, setYetToPunchIn] = useState([]); // Store employees yet to punch in
  const [punchedInToday, setPunchedInToday] = useState(new Set()); // Track punched-in employees
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [formValues, setFormValues] = useState({
    eventTitle: '',
    eventPriority: 'normal',
    eventDate: new Date().toISOString().substr(0, 10),
  });

  const [today] = useState(new Date());
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);



  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  console.log("upcom.....................", upcomingBirthdays);

  const [absent, setAbsent] = useState([]);
  console.log("Absentee", absent);

  // **Fetch Absentees**
  useEffect(() => {
    const fetchAbsentees = async () => {
      try {
        setLoadingAbsent(true);
        const absentees = await VacationDays();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentlyAbsent = absentees.filter(absentee => {
          const fromDate = new Date(absentee.fromDate);
          const toDate = new Date(absentee.toDate);
          return today >= fromDate && today <= toDate;
        });

        setAbsent(currentlyAbsent);
      } catch (error) {
        console.error("Error fetching absentees:", error);
      }
      setLoadingAbsent(false);
    };

    fetchAbsentees();
  }, []);

  useEffect(() => {
    const fetchYetToPunchIn = async () => {
      console.log("🔄 Fetching Employees Yet to Punch In...");

      try {
        setLoadingYetToPunchIn(true);
        const usersData = await UsersPersonalDetail();
        console.log("📌 Users Data Response:", usersData);

        const todayDate = new Date().toISOString().split("T")[0];
        console.log(`📅 Today's Date: ${todayDate}`);

        const attendanceRecords = await fetchAttendanceRecords();
        console.log("📌 Attendance Records Response:", attendanceRecords);

        if (!attendanceRecords || !Array.isArray(attendanceRecords.rows)) {
          console.error("❌ Error: Attendance records response is not an array.", attendanceRecords);
          return;
        }

        // Pre-filter attendance records for today
        const todaysAttendanceRecords = attendanceRecords.rows.filter(record => {
          return record.punch_time.startsWith(todayDate);
        });

        console.log("✅ Today's Attendance Records:", todaysAttendanceRecords);

        // Identify employees yet to punch in
        const punchedInTodaySet = new Set(
          todaysAttendanceRecords.map(record => record.emp_code)
        );

        const employeesYetToPunchIn = usersData.filter(user => {
          return !punchedInTodaySet.has(user.section);
        });

        setYetToPunchIn(employeesYetToPunchIn);
        console.log("🔄 Employees Yet to Punch In:", employeesYetToPunchIn);
      } catch (error) {
        console.error("❌ Error fetching yet-to-punch-in employees:", error);
      }
      setLoadingYetToPunchIn(false);
    };

    fetchYetToPunchIn();
  }, []);

  // useEffect(() => {
  //   const checkLatePunches = async () => {
  //     console.log("🔄 Checking Late Punch-Ins...");

  //     try {
  //       setLoadingLatePunch(true);
  //       setLatePunches([]); // Reset daily

  //       const shiftResponse = await fetchshifts();
  //       console.log("📌 Shift API Response:", shiftResponse);

  //       if (!shiftResponse || !Array.isArray(shiftResponse.rows)) {
  //         console.error("❌ Error: Shifts response is not an array.", shiftResponse);
  //         return;
  //       }

  //       const shifts = shiftResponse.rows;
  //       console.log("✅ Shifts extracted:", shifts);

  //       const today = new Date();
  //       const todayDate = today.toISOString().split("T")[0];

  //       let lateEmployees = [];

  //       yetToPunchIn.forEach(user => {
  //         console.log(`🧑 Checking punch-in status for ${user.name}, Employee Code: ${user.section}`);

  //         // Get shift time or default to 10:00 AM
  //         const userShift = shifts.find(shift => shift.emp_no === user.userid);
  //         let shiftStartTime = new Date(`${todayDate}T10:00:00`);

  //         if (userShift) {
  //           console.log(`⏳ ${user.name} has a shift: ${userShift.hours}`);

  //           const shiftHours = userShift.hours.split(" TO ");
  //           const match = shiftHours[0].match(/(\d+):(\d+)(AM|PM)/);

  //           if (match) {
  //             const [_, startHour, startMinute, startPeriod] = match;
  //             let formattedStartHour = parseInt(startHour, 10);

  //             if (startPeriod === "PM" && formattedStartHour !== 12) formattedStartHour += 12;
  //             if (startPeriod === "AM" && formattedStartHour === 12) formattedStartHour = 0;

  //             shiftStartTime = new Date(today);
  //             shiftStartTime.setHours(formattedStartHour, parseInt(startMinute, 10), 0, 0);
  //           }
  //         } else {
  //           console.log(`⏳ ${user.name} has no assigned shift. Defaulting to 10:00 AM.`);
  //         }

  //         console.log(`🔔 Expected shift start time for ${user.name}: ${shiftStartTime.toLocaleTimeString()}`);

  //         // Apply 30-minute buffer
  //         const lateThreshold = new Date(shiftStartTime);
  //         lateThreshold.setMinutes(shiftStartTime.getMinutes() + 30);

  //         // Watch for new punch-ins
  //         const interval = setInterval(async () => {
  //           const newAttendanceRecords = await fetchAttendanceRecords();
  //           const todaysRecords = newAttendanceRecords.rows.filter(record => record.punch_time.startsWith(todayDate));

  //           const punchRecord = todaysRecords.find(record => record.emp_code === user.section);

  //           if (punchRecord) {
  //             const punchTime = new Date(punchRecord.punch_time);
  //             console.log(`🕒 ${user.name} punched in at ${punchTime.toLocaleTimeString()}`);

  //             if (punchTime > lateThreshold) {
  //               console.log(`🚨 ${user.name} was late!`);
  //               setLatePunches(prevLatePunches => [
  //                 ...prevLatePunches,
  //                 {
  //                   name: user.name,
  //                   punchTime: punchTime.toLocaleTimeString(),
  //                   expectedTime: shiftStartTime.toLocaleTimeString(),
  //                   buffer: "30 min"
  //                 }
  //               ]);
  //             }

  //             // Stop watching once punched in
  //             clearInterval(interval);
  //           }
  //         }, 5000); // Check every 5 seconds
  //       });

  //     } catch (error) {
  //       console.error("❌ Error checking late punches:", error);
  //     }
  //     setLoadingLatePunch(false);
  //   };

  //   checkLatePunches();
  // }, [yetToPunchIn]); // Runs when `yetToPunchIn` updates

  useEffect(() => {
    const fetchProbationEnding = async () => {
      try {
        setLoadingProbation(true);
        const usersData = await UsersPersonalDetail();
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const endingProbations = usersData.filter(user => {
          const probationEndDate = new Date(user.end_of_probation);
          return probationEndDate >= today && probationEndDate <= thirtyDaysFromNow;
        });

        setProbationEnding(endingProbations);
      } catch (error) {
        console.error("Error fetching probation data:", error);
      }
      setLoadingProbation(false);
    };

    fetchProbationEnding(); // ✅ Call function inside useEffect

  }, []); // ✅ Empty dependency array to run only once

  // **Fetch Employees Yet to Punch In**
  useEffect(() => {
    const loadYetToPunchIn = async () => {
      try {
        setLoadingYetToPunchIn(true);
        const usersData = await UsersPersonalDetail();
        const attendanceData = await fetchAttendanceRecords();
        const todayDate = new Date().toISOString().split("T")[0];

        const punchedInTodaySet = new Set(
          attendanceData.rows
            .filter(record => record.punch_time.startsWith(todayDate))
            .map(record => record.emp_code)
        );

        const absentEmployeeNames = new Set(absent.map(emp => emp.empName));
        const employeesYetToPunchIn = usersData.filter(user =>
          user.section &&
          !punchedInTodaySet.has(user.section) &&
          !absentEmployeeNames.has(user.name)
        );

        setYetToPunchIn(employeesYetToPunchIn);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
      setLoadingYetToPunchIn(false);
    };

    loadYetToPunchIn();
  }, [absent]);


  // **Fetch Upcoming Birthdays**
  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        setLoadingBirthdays(true);
        const allUsers = await UsersPersonalDetail();
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const upcoming = allUsers
          .map(user => {
            const birthDate = new Date(user.birth_date);
            let birthDateThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
            if (birthDateThisYear < today) {
              birthDateThisYear.setFullYear(today.getFullYear() + 1);
            }

            const timeDiff = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));

            return {
              ...user,
              timeDiff,
              birthDateThisYear
            };
          })
          .filter(user => user.birthDateThisYear >= today && user.birthDateThisYear <= thirtyDaysFromNow)
          .sort((a, b) => a.timeDiff - b.timeDiff);

        setUpcomingBirthdays(upcoming);
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
      setLoadingBirthdays(false);
    };

    fetchBirthdays();
  }, []);

  const formatTimeLeft = (daysLeft) => {
    const weeks = Math.floor(daysLeft / 7);
    const days = daysLeft % 7;
    if (weeks > 0 && days > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''} left`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} left`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    if (formValues.eventTitle) {
      setEvents([
        ...events,
        {
          date: new Date(formValues.eventDate),
          title: formValues.eventTitle,
          priority: formValues.eventPriority,
        },
      ]);
      toggle();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getEventColor = (priority) => {
    switch (priority) {
      case 'low':
        return 'green';
      case 'high':
        return 'red';
      default:
        return 'blue';
    }
  };




  const handleDateSelection = (date) => {
    setSelectedDate(date); // Update state with the selected date
  };

  const [expiry, setExpiry] = useState([]);
  console.log("Expiring..", expiry);

  // **Fetch Expiring Documents**
  useEffect(() => {
    const fetchExpiringDocuments = async () => {
      try {
        setLoadingDocuments(true);
        const expiringDocs = await fetchDocuments();
        const today = new Date();

        const filteredExpiry = expiringDocs.filter(document => {
          const expiryDate = new Date(document.expiryDate);
          let reminderPeriod = document.docType === "PASSPORT" ? 6 : document.docType === "VISA" ? 1 : 0;
          const reminderDate = new Date(today);
          reminderDate.setMonth(today.getMonth() + reminderPeriod);

          return expiryDate >= today && expiryDate <= reminderDate;
        });

        setExpiry(filteredExpiry);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
      setLoadingDocuments(false);
    };

    fetchExpiringDocuments();
  }, []);




  return (
    <Fragment>
      <style>

      </style>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Row className="card-container">
        {/* Who’s Off / Working Remotely */}
        <Col sm="4" className="card-column">
          <Card className="card-common who-is-off">
            <CardHeader><h5>Who’s Off / Working Remotely 🏡</h5></CardHeader>
            <CardBody>
              {loadingAbsent ? (
                <Spinner color="primary" />
              ) : absent.length > 0 ? (
                <ul>
                  {absent.map((absentee, index) => (
                    <li key={index} className="who-is-off-item">
                      <span className="initial">{absentee.empName.charAt(0)}</span>
                      <div className="absence-info">
                        <p>{absentee.empName}</p>
                        <p>{new Date(absentee.fromDate).toLocaleDateString()} → {new Date(absentee.toDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No one’s off today! 🎉</p>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* Employees Yet to Punch In */}
        <Col sm="4" className="card-column">
          <Card className="card-common yet-to-punch-in">
            <CardHeader><h5>Yet to Punch In ⏳</h5></CardHeader>
            <CardBody>
              {loadingYetToPunchIn ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : yetToPunchIn.length > 0 ? (
                <div className="yet-to-punch-in-container">
                  <ul className="yet-to-punch-in-list">
                    {yetToPunchIn.map((employee, index) => (
                      <li key={index} className="yet-to-punch-in-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div className="employee-info">
                          <p className="name">{employee.name}</p>
                          <p className="job-title">{employee.job_title || "No Job Title"}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="all-punched-in">Everyone has punched in today! 🎉</p>
              )}
            </CardBody>
          </Card>
        </Col>

        {/* Documents Expiring */}
        <Col sm="4" className="card-column">
          <Card className="card-common documents-expiring-soon">
            <CardHeader><h5>Documents Expiring Soon 📄</h5></CardHeader>
            <CardBody>
              {loadingDocuments ? (
                <Spinner color="primary" />
              ) : expiry.length > 0 ? (
                <ul>
                  {expiry.map((doc, index) => (
                    <li key={index} className="expiring-document-item">
                      <span className="initial">{doc.owner.charAt(0)}</span>
                      <div>
                        <p>{doc.owner} - {doc.docType}</p>
                        <p>{new Date(doc.expiryDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No documents expiring soon!</p>
              )}
            </CardBody>
          </Card>
        </Col>

        <Row className="card-container">
          {/* <Col sm="4" className="card-column">
          <Card className="card-common documents-expiring-soon">
            <CardHeader><h5>Late Punch-Ins ⏳</h5></CardHeader>
            <CardBody>
              {loadingLatePunch ? (
                <Spinner color="primary" />
              ) : latePunches.length > 0 ? (
                <ul>
                  {latePunches.map((employee, index) => (
                    <li key={index} className="expiring-document-item">
                      <span className="initial">{employee.name.charAt(0)}</span>
                      <div>
                        <p>{employee.name}</p>
                        <p>Punched in at: {employee.punchTime}</p>
                        <p>Expected: {employee.expectedTime}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No late punch-ins today! 🎉</p>
              )}
            </CardBody>
          </Card>
        </Col> */}
          <Col sm="4" className="card-column">
            <Card className="card-common documents-expiring-soon">
              <CardHeader><h5>Probation Ending in 30 Days 📅</h5></CardHeader>
              <CardBody>
                {loadingProbation ? (
                  <Spinner color="primary" />
                ) : probationEnding.length > 0 ? (
                  <ul>
                    {probationEnding.map((employee, index) => (
                      <li key={index} className="expiring-document-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div>
                          <p>{employee.name} - Ends on {new Date(employee.end_of_probation).toLocaleDateString()}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No probation periods ending soon.</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>






      </Row>

      {/* Upcoming Birthdays and Calendar */}
      <Row className="birthdays-and-calendar mt-4">
        {/* Upcoming Birthdays */}
        <Col sm="6" className="card-column">
          <Card className="card-common upcoming-birthdays">
            <CardHeader><h5>Upcoming Birthdays 🎂</h5></CardHeader>
            <CardBody style={{ maxHeight: "300px", overflowY: "auto" }}>
              {loadingBirthdays ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : upcomingBirthdays.length > 0 ? (
                <ul className="upcoming-birthdays-list">
                  {upcomingBirthdays.map((user, index) => (
                    <li key={index} className="upcoming-birthday-item">
                      <span className="initial">{user.name.charAt(0)}</span>
                      <div className="birthday-info">
                        <p className="name">{user.name}</p>
                        <p className="date">{new Date(user.birthDateThisYear).toLocaleDateString()}</p>
                        <p className="time-left">
                          {user.timeDiff} days left 🎉
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No upcoming birthdays in the next 3 weeks</p>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* Calendar */}
        <Col sm="6">
          <Card>
            <CardHeader><h5>Calendar 📅</h5></CardHeader>
            <CardBody>
              <Calendar value={selectedDate} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* 
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Event</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="eventTitle">Event Title</Label>
              <Input
                type="text"
                name="eventTitle"
                id="eventTitle"
                value={formValues.eventTitle}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventDate">Date</Label>
              <Input
                type="date"
                name="eventDate"
                id="eventDate"
                value={formValues.eventDate}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventPriority">Priority</Label>
              <Input
                type="select"
                name="eventPriority"
                id="eventPriority"
                value={formValues.eventPriority}
                onChange={handleChange}
              >
                <option value="low">Low</option>
                <option value="normal">Normal</option>
                <option value="high">High</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};

export default HrmsAdminDashboard;
