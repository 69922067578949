import api from './api'; // Assuming `api` is your axios instance

// Fetch Not Contacted Leads
export const getNotContactedLeads = async () => {
    return api.get('/v1/crm/SalesReport/not-contacted-leads');
};

// Fetch Organic Leads
export const getOrganicLeads = async () => {
    return api.get('/v1/crm/SalesReport/organic-leads');
};

// Fetch My Deposits
export const getMyDeposits = async () => {
    return api.get('/v1/crm/SalesReport/my-deposits');
};

// Fetch My Withdrawals
export const getMyWithdrawals = async () => {
    return api.get('/v1/crm/SalesReport/my-withdrawals');
};

// Fetch My ActiveIB
export const getActiveIB = async () => {
    return api.get('/v1/crm/SalesReport/Active-IB');
};

// Fetch My InActiveIB
export const getInActiveIB = async () => {
    return api.get('/v1/crm/SalesReport/InActive-IB');
};

// Fetch My DormantIB
export const getDormantIB = async () => {
    return api.get('/v1/crm/SalesReport/dormant-ib');
};

// Fetch My MyIb
export const getMyIB = async () => {
    return api.get('/v1/crm/SalesReport/my-ib');
};

// Fetch My RebateIB
export const getRebateIB = async () => {
    return api.get('/v1/crm/SalesReport/rebate-ib');
};

// Fetch My CPAIB
export const getCPAIB = async () => {
    return api.get('/v1/crm/SalesReport/cpa-ib');
};

// Fetch My NTPIB
export const getNTPIB = async () => {
    return api.get('/v1/crm/SalesReport/ntp-ib');
};

// Fetch My MasterIB
export const getMasterIB = async () => {
    return api.get('/v1/crm/SalesReport/master-ib');
};

// Fetch Group Members by ID
export const getGroupMembersById = async (groupId) => {
    return api.get(`v1/crm/SalesReport/group-members/${groupId}`);
};