import {
  Home,
  Settings,
  Layers,
  Users,
  UserMinus,
  Phone,
  Database,
  DollarSign,
  BarChart2,
  UserCheck,
  Sliders
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Sample",
    menucontent: "All necessary Sample added",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/leads`,
        icon: UserMinus,
        title: "Leads",
        type: "link",
        permission: 'leads:view'
      },
      {
        path: `${process.env.PUBLIC_URL}/clients/all-clients`,
        icon: Users,
        title: "Clients",
        type: "link",
        permission: 'clients:view'
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/corporates/all-corporates`,
      //   icon: Users,
      //   title: "Corporates",
      //   type: "link",
      //   permission: 'clients:view'
      // },
      {
        path: `${process.env.PUBLIC_URL}/ibs`,
        icon: Users,
        title: "Partners",
        type: "link",
        permission: 'ibs:view'
      },
      {
        title: "Tickets",
        path: `${process.env.PUBLIC_URL}/tickets`,
        type: "link",
        icon: Layers,
        permission: 'tickets:view'
      },

      {
        icon: Database,
        title: "Reports",
        path: `${process.env.PUBLIC_URL}/SalesReport`,
        type: "link",
        permission: 'salesReport:view'
      },
      {
        title: "Finance",
        icon: DollarSign,
        type: "sub",
        badge2: true,
        active: false,
        permission: 'transactions:view',
        children: [
          // {
          //   title: "Finance Dashboard",
          //   path: `${process.env.PUBLIC_URL}/financeDashboard`,
          //   type: "link",
          //   permission: "finance:view",
          // },
          {
            title: "Financial Reports",
            path: `${process.env.PUBLIC_URL}/financial-reports`,
            type: "link",
            permission: 'reports:view'
          },
          {
            title: "Transactions",
            type: "sub",
            active: true,
            permission: "transactions:view",
            children: [
              {
                title: "Deposits",
                path: `${process.env.PUBLIC_URL}/deposits`,
                type: "innerlink",
                permission: "transactions:view",
              },
              {
                title: "Withdrawals",
                path: `${process.env.PUBLIC_URL}/withdrawals`,
                type: "innerlink",
                permission: "transactions:view",
              },
              {
                title: "Internal Transfers",
                path: `${process.env.PUBLIC_URL}/internal-transfers`,
                type: "innerlink",
                permission: "transactions:view",
              },
            ]
          },
          {
            title: "Overview Report",
            path: `${process.env.PUBLIC_URL}/OverviewReport`,
            type: "link",
            permission: 'OverViewreport:view'
          },
          // {
          //   title: "Bank and Mediums",
          //   path: `${process.env.PUBLIC_URL}/financeBank`,
          //   type: "link",
          //   permission: "finance:view",
          // },
          // {
          //   title: "Account Lots",
          //   path: `${process.env.PUBLIC_URL}/AccountLots`,
          //   type: "link",
          //   permission: "finance:view",
          // },
        ],
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/transactions`,
      //   title: "Transactions",
      //   icon: Sliders,
      //   type: "sub",
      //   badge2: true,
      //   permission: 'transactions:view',
      //   children: [
      //     {
      //       title: "Deposits",
      //       path: `${process.env.PUBLIC_URL}/transactions/deposits`,
      //       type: "link",
      //       permission: 'transactions:view'
      //     },
      //     {
      //       title: "Withdrawals",
      //       path: `${process.env.PUBLIC_URL}/transactions/withdrawals`,
      //       type: "link",
      //       permission: 'transactions:view'
      //     },
      //     {
      //       title: "Internal Transfers",
      //       path: `${process.env.PUBLIC_URL}/transactions/internal-transfers`,
      //       type: "link",
      //       permission: 'transactions:view'
      //     },
      //   ],
      // },
      {
        title: "Operation",
        icon: BarChart2,
        type: "sub",
        badge2: true,
        active: false,
        permission: 'Operation:view',
        children: [
          {
            title: "Swap Report",
            path: `${process.env.PUBLIC_URL}/swap-report`,
            type: "link",
            permission: 'Operation:view'
          },
          {
            title: "SwapGroup Mapping",
            path: `${process.env.PUBLIC_URL}/swapgroup-mapping`,
            type: "link",
            permission: 'Operation:view'
          },
          {
            title: " Bonus Plugin",
            path: `${process.env.PUBLIC_URL}/bonus-plugin`,
            type: "link",
            permission: 'Operation:view'
          },
          {
            title: " Bonus Report",
            path: `${process.env.PUBLIC_URL}/bonus-report`,
            type: "link",
            permission: 'Operation:view'
          },
          {
            title: " Trading Group Defined",
            path: `${process.env.PUBLIC_URL}/trading-group-defined`,
            type: "link",
            permission: 'Operation:view'
          },
        ],
      },
      // {
      //   title: "HRMS",
      //   icon: UserCheck,
      //   type: "sub",
      //   badge2: true,
      //   active: false,
      //   permission: 'hrms:view',
      //   children: [
      //     {
      //       title: "Dashboard",
      //       path: `${process.env.PUBLIC_URL}/HrmsAdminDashboard`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "Employee Management",
      //       path: `${process.env.PUBLIC_URL}/AdminEmployeeManagement`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "Time Management",
      //       path: `${process.env.PUBLIC_URL}/AdminTimeManagment`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "Attendance & Time Tracking",
      //       path: `${process.env.PUBLIC_URL}/Attendance`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "KPI",
      //       path: `${process.env.PUBLIC_URL}/`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "Payroll",
      //       type: "sub",
      //       children: [        // Subdivisions of "Other"
      //         {
      //           title: "Financial Requests",
      //           path: `${process.env.PUBLIC_URL}/FinancialRequests`,
      //           type: "link",
      //           permission: 'Operation:view'
      //         },
      //         {
      //           title: "Loan Requests",
      //           path: `${process.env.PUBLIC_URL}/LoanRequests`,
      //           type: "link",
      //           permission: 'Operation:view'
      //         },
      //       ]
      //     },
      //     {
      //       title: "Documents & Letters",
      //       path: `${process.env.PUBLIC_URL}/Documents & Letters`,
      //       type: "link",
      //       permission: 'Operation:view'
      //     },
      //     {
      //       title: "Other",    // New "Other" option
      //       type: "sub",       // Indicates it has subdivisions
      //       children: [        // Subdivisions of "Other"
      //         {
      //           title: "Manage Tasks",
      //           path: `${process.env.PUBLIC_URL}/Manage Tasks`,
      //           type: "link",
      //           permission: 'Operation:view'
      //         },

      //       ]
      //     }
      //   ]
      // },

      {
        title: "Marketing",
        icon: Home,
        type: "sub",
        badge2: true,
        active: true,
        permission: 'marketing:view',
        children: [
          {
            title: "Marketing Dashboard",
            path: `${process.env.PUBLIC_URL}/marketing-dashboard`,
            type: "link",
            permission: 'marketing:view',
          },
          {
            title: "Marketing Report",
            path: `${process.env.PUBLIC_URL}/marketing-report`,
            type: "link",
            permission: 'marketing:view',
          },
        ],
      },
      {
        title: "HRMS",
        icon: UserCheck,
        type: "sub",
        badge2: true,
        active: false,
        permission: 'hrms:view',
        children: [
          {
            title: "Dashboard",
            path: `${process.env.PUBLIC_URL}/HrmsAdminDashboard`,
            type: "link",
            permission: 'hrms:view'
          },
          {
            title: "Employee Management",
            path: `${process.env.PUBLIC_URL}/AdminEmployeeManagement`,
            type: "link",
            permission: 'hrms:viewAll'
          },
          {
            title: "Time Management",
            path: `${process.env.PUBLIC_URL}/AdminTimeManagment`,
            type: "link",
            permission: 'hrms:view'
          },
          {
            title: "Attendance & Time Tracking",
            path: `${process.env.PUBLIC_URL}/Attendance`,
            type: "link",
            permission: 'hrms:view'
          },
          // {
          //   title: "KPI",
          //   path: `${process.env.PUBLIC_URL}/`,
          //   type: "link",
          //   permission: 'hrms:view'
          // },
          {
            title: "Payroll",
            type: "sub",
            children: [        // Subdivisions of "Other"
              {
                title: "Financial Requests",
                path: `${process.env.PUBLIC_URL}/FinancialRequests`,
                type: "link",
                permission: 'hrms:view'
              },
              {
                title: "Loan Requests",
                path: `${process.env.PUBLIC_URL}/LoanRequests`,
                type: "link",
                permission: 'hrms:view'
              },
              {
                title: "Employee Salaries",
                path: `${process.env.PUBLIC_URL}/EmployeeSalaries`,
                type: "link",
                permission: 'hrms:view'
              },
              {
                title: "Employee Commissions",
                path: `${process.env.PUBLIC_URL}/EmployeeCommissions`,
                type: "link",
                permission: 'hrms:view'
              },



            ]
          },
          {
            title: "Documents & Letters",
            path: `${process.env.PUBLIC_URL}/Documents & Letters`,
            type: "link",
            permission: 'hrms:view'
          },
          {
            title: "HR Policies",
            path: `${process.env.PUBLIC_URL}/HRPolicies`,
            type: "link",
            permission: 'hrms:view'
          },
          {
            title: "Other",    // New "Other" option
            type: "sub",       // Indicates it has subdivisions
            children: [        // Subdivisions of "Other"
              {
                title: "Manage Tasks",
                path: `${process.env.PUBLIC_URL}/Manage Tasks`,
                type: "link",
                permission: 'hrms:view'
              },
              {
                title: "End Of Services",
                path: `${process.env.PUBLIC_URL}/EndOfService`,
                type: "link",
                permission: 'hrms:view'
              },


            ]
          }
        ]
      },

      {
        path: `${process.env.PUBLIC_URL}/clients`,
        icon: Users,
        title: "Compliance",
        type: "sub",
        badge2: true,
        permission: 'others:compliance',
        children: [
          {
            title: "Other Clients x M",
            path: `${process.env.PUBLIC_URL}/clients/other-clients-x-m`,
            type: "link",
            permission: 'clientDetails:otherClients(x-M)'
          },
          {
            title: "Action Needed",
            path: `${process.env.PUBLIC_URL}/clients/action-needed`,
            type: "link",
            permission: 'clientDetails:actionNeeded'
          },
          // {
          //   title: "Morocco Documents",
          //   path: `${process.env.PUBLIC_URL}/clients/morocco-documents`,
          //   type: "link",
          //   permission: 'clientDetails:morDocs'
          // }
        ],
      },
      {
        path: `https://godoltd.sharepoint.com/:f:/s/GoDoPolicies/EsK0SpL3yeNOrXE_M7T5yG8BUOnFYptTp8wN56faRmOeCQ`,
        icon: Layers,
        title: "Policies",
        type: "blank",
        permission: 'godoPolicies:view'
      },
      {
        path: `${process.env.PUBLIC_URL}/directory`,
        icon: Phone,
        title: "Directory",
        type: "link",
        permission: 'others:extension',
      },
      {
        title: "Settings",
        icon: Settings,
        type: "sub",
        badge2: true,
        active: false,
        permission: 'others:settings',
        children: [
          {
            title: "Users",
            path: `${process.env.PUBLIC_URL}/users`,
            type: "link",
            permission: 'users:view'
          },
          {
            title: "Roles",
            path: `${process.env.PUBLIC_URL}/roles`,
            type: "link",
            permission: 'roles:view'
          },
          {
            title: "Groups",
            path: `${process.env.PUBLIC_URL}/groups`,
            type: "link",
            permission: 'users:view'
          },
          {
            title: "Account Types",
            path: `${process.env.PUBLIC_URL}/account-types`,
            type: "link",
            permission: 'accountTypes:view'
          },
          {
            title: "Banners",
            path: `${process.env.PUBLIC_URL}/banners`,
            type: "link",
            permission: 'banners:view'
          },
          {
            title: "Videos",
            path: `${process.env.PUBLIC_URL}/videos`,
            type: "link",
            permission: 'users:view'
          },
          {
            title: "System Emails",
            path: `${process.env.PUBLIC_URL}/settings/system-emails`,
            type: "link",
            permission: 'systemEmails:view'
          },
          {
            title: "User Logs",
            path: `${process.env.PUBLIC_URL}/settings/user-logs`,
            type: "link",
            permission: 'users:view'
          },
          {
            title: "Email Logs",
            path: `${process.env.PUBLIC_URL}/settings/email-logs`,
            type: "link",
            permission: 'users:view'
          },
        ],
      },
    ],
  },
];

