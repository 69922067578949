import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const MyWithdrawalsReport = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  // Access roleId and userId from Redux store
  const profile = useSelector((state) => state.login && state.login.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  // Format the date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  // Define table columns
  const columns = [
    {
      name: "Created Date",
      selector: (row) => formatDate(row.CreatedDate),
      sortable: true,
    },
    { name: "IB", selector: (row) => row.IB, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Name", selector: (row) => row.Name, sortable: true },
    { name: "Customer ID", selector: (row) => row.CustomerID, sortable: true },
    { name: "Account", selector: (row) => row.ACNumber, sortable: true },
    {
      name: "Status",
      selector: (row) => row.WithdrawalStatus,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge ${row.WithdrawalStatus === "PENDING"
              ? "bg-primary"
              : row.WithdrawalStatus === "APPROVED"
                ? "bg-success"
                : row.WithdrawalStatus === "REJECTED"
                  ? "bg-danger"
                  : "bg-secondary"
            }`}
          style={{
            width: row.WithdrawalStatus === "PENDING" || row.WithdrawalStatus === "REJECTED" ? "65.6667px" : "auto",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {row.WithdrawalStatus}
        </span>
      ),
    },

    {
      name: "Amount",
      selector: (row) => `$${Number(row.Amount || 0).toLocaleString()}`,
      sortable: true,
    },
  ];

  // Fetch group members by userId
  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          // console.log("Full Group Members Response:", response);
          setGroupMembers(response); // Store the group members
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]); // Reset group members if the API fails
        }
      };

      fetchGroupMembers();
    }
  }, [userId]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SalesReportService.getMyWithdrawals();

        // Filter data based on role
        let filteredResponse = response;
        // If the role is not in the specified ones and groupMembers is not empty, filter by group members
        if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
          if (groupMembers.length > 0) {
            // Filter deposits by matching agentId with group members
            filteredResponse = response.filter((item) =>
              groupMembers.includes(item.agentId)
            );
          } else {
            // If no group members are found, filter by userId
            filteredResponse = response.filter((item) => item.agentId === userId);
          }
        }

        setData(filteredResponse);
        setFilteredData(filteredResponse);
      } catch (err) {
        setError("Failed to fetch data.");
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId, userId, groupMembers]);

  // Apply search term and date range filtering
  useEffect(() => {
    let filtered = [...data];

    // Apply search term filter
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }

    // Apply start date filter
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) >= new Date(startDate)
      );
    }

    // Apply end date filter
    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  // Prepare the data for the Bar Chart
  const getBarChartData = () => {
    const salesSums = filteredData.reduce((acc, item) => {
      const salesName = item.Sales || "Unknown"; // Use Sales name, default to "Unknown" if missing

      if (!acc[salesName]) {
        acc[salesName] = 0;
      }

      acc[salesName] += item.Amount || 0;
      return acc;
    }, {});

    return {
      labels: Object.keys(salesSums), // Sales names as labels
      datasets: [
        {
          label: "Total Withdrawals ",
          data: Object.values(salesSums), // Corresponding withdrawal amounts
          backgroundColor: "#086CA2",
          borderColor: "#086CA2",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <div style={{ position: "relative" }}>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={2}>
          {/* Bar chart only */}
          {showCharts && (
            <Grid item xs={12} md={8}>
              <div style={{ marginBottom: "20px", width: "100%" }}>
                <Bar data={getBarChartData()} options={{ responsive: true }} />
              </div>
            </Grid>
          )}
          {/* DataTable */}
          <Grid item xs={12} md={12}>
            <DataTable
              columns={columns}
              data={filteredData || []}
              pagination
              striped
              persistTableHead
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default MyWithdrawalsReport;
