// import React, { useEffect, useState } from 'react';
// import { Card, CardBody, Row, Col, Media, Input } from 'reactstrap';
// import Accounts from '../../../assets/images/avtar/accounts.svg';

// import { Doughnut } from "react-chartjs-2";
// import { Chart, ArcElement, Tooltip, Legend } from "chart.js";


// // Register Chart.js components
// Chart.register(ArcElement, Tooltip, Legend);

// const Dashboard = () => {
//       // Chart Data
//   const chartData = {
//     labels: ["Converted", "Other Leads"],
//     datasets: [
//       {
//         data: [37, 63], // Example values (37% converted, 63% other)
//         backgroundColor: ["#4CAF50", "#D3D3D3"], // Green for converted, Gray for others
//         hoverBackgroundColor: ["#45a049", "#a9a9a9"],
//       },
//     ],
//   };

//   // Chart Options
//   const chartOptions = {
//     cutout: "70%", // Creates a donut effect
//     plugins: {
//       legend: {
//         display: false, // Hide legend
//       },
//       tooltip: {
//         enabled: true,
//       },
//     },
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//              {/* Adding the new div before the SalesReports content */}
//       <div style={{ height: "20px" }} className="container-fluid">
//         <div className="page-title" style={{ display: "none" }}>
//           <div className="row">
//             <div className="col-6"></div>
//             <div className="col-6">
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <a href="/dashboard">Dashboard</a>
//                 </li>
//                 <li className="breadcrumb-item active">Clients</li>
//               </ol>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex justify-between items-center mb-6">
//       <h4 style={{ color: 'black' }} className="text-2xl font-bold">Overview</h4>

//         <div className="flex items-center gap-2">
//         <Input type="select" style={{ maxWidth: '150px ', maxHeight: '39px' }} className="border rounded px-3 py-6 w-48 ">
//             <option>Platform</option>
//             <option>Web</option>
//             <option>Mobile</option>
//         </Input>
//         <Input type="select" style={{ maxWidth: '120px', maxHeight: '39px' }} className="border rounded px-3 py-2 w-48 ">
//             <option>Campaign</option>
//             <option>SEO</option>
//             <option>PPC</option>
//         </Input>

//         <Input type="date" className="border rounded px-3 py-2 w-48 h-10" />
//         <Input type="date" className="border rounded px-3 py-2 w-48 h-10" />
//         </div>


//       </div>
      
//       <Card className="p-6 mb-6 flex flex-row justify-between items-center" style={{ marginTop: "20px", height: "244px" }}>
      
//       {/* Left Side - Leads Count & Buttons */}
//       <div className="w-1/2 flex flex-col justify-center p-4">
//         <CardBody className="flex items-center gap-4">
//           <Media left>
//             <img src={Accounts} alt="Accounts" className="w-10" />
//           </Media>
//           <Media body>
//             <h2 className="text-lg font-semibold">850</h2>
//             <p className="text-gray-500">Total Leads</p>
//           </Media>
//         </CardBody>

// {/* Buttons Section - Auto-Assign Colors */}
// <div className="flex flex-wrap gap-2 mt-4">
//   {[
//     "New Leads (80)",
//     "ReOpen (50)",
//     "Re-Engage (45)",
//     "Not Contacted (20)",
//     "Not Interested (80)",
//     "Not Reachable (32)",
//     "Call Later (40)",
//     "Warm (56)",
//     "Hot Interested (43)",
//     "Wrong Number (32)",
//     "Converted (54)",
//     "Don't Call Again (24)"
//   ].map((label, index) => {
//     // Define a set of 7 colors to cycle through
//     const colors = ["#DE0D92", "#f28b82", "#fbbc04", "#34a853", "#4285f4", "#a142f4", "#ff6d00"];

//     // Pick a color based on index (cycles through colors automatically)
//     const color = colors[index % colors.length];

//     return (
//       <button
//         key={index}
//         className="px-4 py-2 text-sm font-semibold rounded-lg"
//         style={{
//           height: "41px",  // Adjusted for better readability
//           minWidth: "120px", // Ensure consistent button width
//           border: `1px solid ${color}`, // Border color
//           color: color, // Text color
//           backgroundColor: `${color}1A`, // 10% opacity background color
//         }}
//       >
//         {label}
//       </button>
//     );
//   })}
// </div>


//       </div>

//             {/* Right Side - Chart */}
//             <div className="w-1/2 flex justify-center items-center">
//             <div style={{ width: "192px", height: "192px", marginTop: "26px", marginRight: "16px" }}>
//                 <Doughnut data={chartData} options={chartOptions} />
//             </div>
//             </div>


//     </Card>
      
//       <Row className="mb-6">
//         <Col md={6}>
//           <Card className="p-6">
//             <CardBody>
//               <h3 className="text-lg font-semibold">Registered Clients</h3>
//               <p className="text-2xl font-bold">813</p>
//               <p className="text-sm text-gray-500">Paid: 340 | Organic: 230</p>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="p-6">
//             <CardBody>
//               <h3 className="text-lg font-semibold">Deposit Amount</h3>
//               <p className="text-2xl font-bold">$9018490</p>
//               <p className="text-sm text-gray-500">Paid: $9018490 | Organic: $3500</p>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
      
//       <Card className="p-6 mb-6">
//         <CardBody>
//           <h3 className="text-lg font-semibold">Clients vs Deposits</h3>
//         </CardBody>
//       </Card>
      
//       <div className="mb-6">
//         <h3 className="text-lg font-semibold mb-4">Agents</h3>
//         <Row>
//           {[
//             { name: "Lily French", role: "Director Direct Sales" },
//             { name: "Howard Adkins", role: "Director Direct Sales" },
//             { name: "Earl Bowman", role: "Director Direct Sales" },
//             { name: "Patrick Padilla", role: "Director Direct Sales" }
//           ].map((agent, index) => (
//             <Col md={3} key={index}>
//               <Card className="p-4 text-center">
//                 <CardBody>
//                   <div className="w-16 h-16 bg-gray-300 rounded-full mx-auto mb-2"></div>
//                   <h4 className="text-md font-semibold">{agent.name}</h4>
//                   <p className="text-sm text-gray-500">{agent.role}</p>
//                 </CardBody>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Media } from 'reactstrap';
import Accounts from '../../../assets/images/avtar/accounts.svg';
import Deposit from '../../../assets/images/avtar/deposits.svg';
import { clientService } from "Services";
import Filter from '../../Common/Component/filter';


const Index = () => {
    const [rec, setRec] = useState({});
    useEffect(() => {
        loadData();
    }, []);

    // Function to separate page and limit, and construct body params
    const getParamsAndBody = (url) => {
        const queryParams = extractUrlParams(url);
        const bodyParams = queryParams;

        return {
            bodyParams
        };
    };

    // Function to extract query parameters from the URL
    const extractUrlParams = (url) => {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        const queryParams = {};
        for (const [key, value] of params) {
            queryParams[key] = value;
        }

        return queryParams;
    };
    const loadData = () => {
        const currentUrl = window.location.href;
        let { bodyParams } = getParamsAndBody(currentUrl);
        clientService
            .getMarketingStats({ bodyParams })
            .then((res) => {
                setRec({
                    ...res,
                    loading: false,
                });
                console.log("result is ", res);
            })
            .catch((err) => {
                console.log('err ', err);
            });
    };

    const appendParameterToUrl = (key, value) => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URL object
        const url = new URL(currentUrl);

        // Check if the parameter exists
        if (url.searchParams.has(key)) {
            // Update the existing parameter
            url.searchParams.set(key, value);
        } else {
            // Append the new parameter
            url.searchParams.append(key, value);
        }

        if ((key == 'callStatus' && value == '') || (key == 'source' && value == '') || (key == 'utmSource' && value == '')
            || (key == 'utmCampaign' && value == '')) {
            url.searchParams.delete(key);
        }
        // Update the browser's address bar
        window.history.pushState(null, '', url.toString());
        loadData();
    };
    return (
        <Row>
            <Filter filter={appendParameterToUrl} show={'marketing'} />
            <Row>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_1'>
                        <div className='p-3 card-headers'>
                            Clients
                        </div>
                        <CardBody>
                            {rec &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Accounts} />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.leadStats ? (parseInt(rec.leadStats.paidClients) + parseInt(rec.leadStats.organicClients)) : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.paidClients : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.organicClients : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_2'>
                        <div className='p-3 card-headers'>
                            Leads
                        </div>
                        <CardBody>
                            {rec && rec.leadStats &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Accounts} />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.leadStats ? (parseInt(rec.leadStats.paidLeads) + parseInt(rec.leadStats.organicLeads)) : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.paidLeads : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.leadStats ? rec.leadStats.organicLeads : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xl='4 xl-33' className={`chart_data_right `}>
                    <Card className='color_3'>
                        <div className='p-3 card-headers'>
                            Deposits
                        </div>
                        <CardBody>
                            {rec &&
                                <Media className='align-items-center'>
                                    <Media body className='right-chart-content'>
                                        <div className='flex gap_20'>
                                            <div>
                                                <img src={Deposit} alt='' />
                                            </div>
                                            <div>
                                                <span className='f_24 semibold'>{rec.deposits ? (parseInt(rec.deposits.paidDeposits) + parseInt(rec.deposits.organicDeposits)) + '$' : '0'}</span><br />
                                                <span className='new-boxs gray'>{'Total Deposits'}</span>
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='gray'>{'Paid'}</span><br />
                                                <span className='f_20 semibold'>{rec.deposits ? parseInt(rec.deposits.paidDeposits) + '$' : '0'}</span>
                                            </div>
                                            <div>
                                                <span className='gray'>{'Organic'}</span><br />
                                                <span className='f_20 semibold'>{rec.deposits ? parseInt(rec.deposits.organicDeposits) + '$' : '0'}</span>
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Row>
    );
};

export default Index;
