import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';
ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const MyIBReport = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);

  const profile = useSelector((state) => state.login?.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const columns = [
    { name: "Created Date", selector: (row) => formatDate(row.CreatedDate), sortable: true },
    { name: "IB ID", selector: (row) => row.IBID, sortable: true },
    { name: "IB Name", selector: (row) => row.IBName, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Outstanding Rebate", selector: (row) => `$${Number(row.Outstanding_Rebate || 0).toLocaleString()}`, sortable: true },
    { name: "Payout", selector: (row) => `$${Number(row.payout || 0).toLocaleString()}`, sortable: true },
    { name: "Total Rebate", selector: (row) => `$${Number(row.Total_Rebate || 0).toLocaleString()}`, sortable: true },
  ];

  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          setGroupMembers(response);
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]);
        }
      };
      fetchGroupMembers();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await SalesReportService.getMyIB();
        let filteredResponse = response;
        if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
          filteredResponse = groupMembers.length > 0
            ? response.filter((item) => groupMembers.includes(item.agentId))
            : response.filter((item) => item.agentId === userId);
        }
        setData(filteredResponse);
        setFilteredData(filteredResponse);
      } catch (err) {
        setError("Failed to fetch data.");
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [roleId, userId, groupMembers]);

  useEffect(() => {
    let filtered = data;
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) => value && value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }
    if (startDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) >= new Date(startDate));
    }
    if (endDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) <= new Date(endDate));
    }
    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  return (
    <div style={{ position: "relative" }}>
                       <style>
        {`
          .chartbutton {
           display: none;
          }
        `}
      </style>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
          <div className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <DataTable columns={columns} data={filteredData} pagination striped persistTableHead />
          </Grid>
        </Grid>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", color: "#007bff" }}>
          No data available.
        </div>
      )}
    </div>
  );
};

export default MyIBReport;
