import React, { useState, useEffect, Fragment } from 'react';
import { Col, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";

import { UsersPersonalDetail, updateUsersPersonalDetail, deleteUsersPersonalDetail, addImage, createUsersPersonalDetail, getDocumentSas } from 'Services/usersPersonalDetail.service';
import { UsersBankAccounts, createUsersBankAccounts, updateUsersBankAccounts, deleteUsersBankAccounts } from 'Services/usersBankAccounts.service';
import { UsersFamilyDetail, createUsersFamilyDetail, updateUsersFamilyDetail, deleteUsersFamilyDetail } from 'Services/usersFamilyDetail.service';
import { UsersInsurances, createUsersInsurances, updateUsersInsurances, deleteUsersInsurances } from 'Services/usersInsurances.service';
import { fetchUsersAssets, createUsersAssets, updateUsersAssets, deleteUsersAssets } from 'Services/usersAssets.service';
import { UsersTicketAllowances, createUsersTicketAllowances, updateUsersTicketAllowances, deleteUsersTicketAllowances } from 'Services/usersTicketAllowances.service';
import { UsersContracts, createUsersContracts, updateUsersContracts, deleteUsersContracts } from 'Services/usersContracts.service';
import { UsersWarning, createUsersWarning, updateUsersWarning, deleteUsersWarning } from 'Services/usersWarning.service';
import { UsersTimeOff, createUsersTimeOff, updateUsersTimeOff, deleteUsersTimeOff } from 'Services/usersTimeoff.service';
import { fetchUsersCommissions, createUsersCommissions, updateUsersCommissions, deleteUsersCommissions } from 'Services/userCommissions.service';
import { fetchDocuments, getDocumentSs } from 'Services/managedocs.service';
import { fetchemployeeS } from 'Services/employeeS.service';
import { toast } from 'react-toastify';
import AddUserModal from './modal/personal-tab/AddNewUser';
import UserInfoModal from './modal/personal-tab/UserInfoModal';
import PersonalInfoModal from './modal/personal-tab/PersonalInfoModal';
import ContactInfoModal from './modal/personal-tab/ContactInfoModal';
import ProfessionalInfoModal from './modal/personal-tab/ProfessionalInfoModal';
import DeleteConfirmationModal from './modal/personal-tab/DeleteConfirmationModal';
import './AdminEmployeeManagement.css'
  ;

const AdminEmployeeManagement = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Profile');
  const [personData, setPersonData] = useState({ SALES: [], MARKETING: [], IT: [], DEALING: [], COMPLIANCE: [], FINANCE: [], MANAGMENT: [], HR: [] });
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [personalInfoModal, setPersonalInfoModal] = useState(false);
  const [contactInfoModal, setContactInfoModal] = useState(false);
  const [professionalInfoModal, setProfessionalInfoModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addImgModal, setAddImgModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [bankAccountFormValues, setBankAccountFormValues] = useState({
    bank_name: '',
    branch: '',
    account_number: '',
    iban: '',
    SWIFTcode: ''
  });
  const [timeOff, setTimeOff] = useState([]);
  const [timeOffModal, setTimeOffModal] = useState(false);
  const [timeOffFormValues, setTimeOffFormValues] = useState({
    timeoffType: '',
    customSetup: '',
    openBalance: '',
    carryBalance: ''

  });

  const [isEditMode, setIsEditMode] = useState(false);

  // Family info states
  const [familyDetails, setFamilyDetails] = useState([]);
  const [familyDetailModal, setFamilyDetailModal] = useState(false);
  const [familyFormValues, setFamilyFormValues] = useState({
    employeeName: '',
    nationality: '',
    homeAddress: '',
    homeEcontactName: '',
    homeEcontactNumber: '',
    UAEaddress: '',
    UAEeContactName: '',
    UAEeContactNumber: '',
    UAEpNumber: '',
    homePnumber: ''
  });
  const [isFamilyEditMode, setIsFamilyEditMode] = useState(false);
  // Commissions info states
  const [commissions, setCommissions] = useState([]);
  const [commissionModal, setCommissionModal] = useState(false);
  const [isCommissionEditMode, setIsCommissionEditMode] = useState(false);
  const [commissionFormValues, setCommissionFormValues] = useState({
    empName: '',
    empNo: '',
    Commission: '',
    monthYear: ''
  });

  // Insurance info states
  const [insurances, setInsurances] = useState([]);
  const [insuranceModal, setInsuranceModal] = useState(false);
  const [insuranceFormValues, setInsuranceFormValues] = useState({
    employeeName: '',
    insurerName: '',
    startDate: '',
    endDate: '',
    deduction: ''
  });

  const [isInsuranceEditMode, setIsInsuranceEditMode] = useState(false);

  // Ticket Allowance info states
  const [ticketAllowances, setTicketAllowances] = useState([]);
  const [ticketAllowanceModal, setTicketAllowanceModal] = useState(false);
  const [ticketAllowanceFormValues, setTicketAllowanceFormValues] = useState({
    employeeName: '',
    transactionType: '',
    date: '',
    active: true
  });
  const [isTicketAllowanceEditMode, setIsTicketAllowanceEditMode] = useState(false);

  // Contract info states
  const [contracts, setContracts] = useState([]);
  const [contractModal, setContractModal] = useState(false);
  const [contractFormValues, setContractFormValues] = useState({
    contractName: '',
    startDate: '',
    endDate: '',
    contractType: ''
  });
  const [isContractEditMode, setIsContractEditMode] = useState(false);

  // Warning info states
  const [warnings, setWarnings] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const [warningFormValues, setWarningFormValues] = useState({
    reason: '',
    managementfile: '',
    submitionfile: '',
    Date: ''
  });
  const [isWarningEditMode, setIsWarningEditMode] = useState(false);

  const [formValues, setFormValues] = useState({
    name: '',
    employment_number: '',
    userid: '',
    img: '',
    gender: '',
    birth_date: '',
    marital_status: '',
    nationality: '',
    national_id: '',
    email: '',
    mobile_number: '',
    phone_no_ext: '',
    job_title: '',
    branch: '',
    work_type: '',
    work_location: '',
    direct_manager: '',
    direct_managerId: '',
    department: '',
    hiring_date: '',
    end_of_probation: ''

  });

  const fetchUserDocuments = async () => {
    if (!selectedPerson || !selectedPerson.userid) return; // Ensure we have a valid user ID

    try {
      const allDocuments = await fetchDocuments(); // Fetch all documents
      console.log("All Documents:", allDocuments);

      // Filter documents where `userId` matches `selectedPerson.userid`
      const userDocuments = allDocuments.filter(doc => doc.userId === selectedPerson.userid);

      setDocuments(userDocuments);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]);
    }
  };

  // Fetch documents whenever `selectedPerson` changes
  useEffect(() => {
    fetchUserDocuments();
  }, [selectedPerson]);
  const getDUrl = async (item) => {
    try {
      const res = await getDocumentSs(item, item.id);
      console.log('Response from getDocumentSs:', res);

      if (res && res.url) {
        window.open(res.url, '_blank');
      } else {
        toast.error('Failed to generate Link: No URL returned');
      }
    } catch (err) {
      console.error('Error generating link:', err);
      toast.error('Failed to generate Link');
    }
    return false;
  };

  const fetchCommissions = async () => {
    if (selectedPerson) {
      try {
        const allCommissions = await fetchUsersCommissions();
        console.log("API Response from fetchUsersCommissions:", allCommissions);

        // Extract rows from response
        const commissionsData = allCommissions.rows || []; // Ensure it's an array

        if (!Array.isArray(commissionsData)) {
          console.error("Expected an array in rows but got:", commissionsData);
          setCommissions([]); // Prevent errors
          return;
        }

        const filteredCommissions = commissionsData.filter(c => c.empNo === selectedPerson.userid);
        console.log("Filtered Commissions:", filteredCommissions);

        setCommissions(filteredCommissions);
      } catch (error) {
        console.error('Error fetching commissions:', error);
        setCommissions([]);
      }
    }
  };


  useEffect(() => {
    fetchCommissions();
  }, [selectedPerson]);

  const handleAddCommission = () => {
    setIsCommissionEditMode(false);
    setCommissionFormValues({
      empName: selectedPerson.name,
      empNo: selectedPerson.userid,
      Commission: '',
      monthYear: ''
    });
    setCommissionModal(true);
  };

  const handleEditCommission = (commission) => {
    setIsCommissionEditMode(true);
    setCommissionFormValues(commission);
    setCommissionModal(true);
  };

  const handleDeleteCommission = (id) => {
    deleteUsersCommissions(id)
      .then(() => {
        toast.success('Commission deleted successfully!');
        fetchCommissions();
      })
      .catch(error => {
        toast.error('Failed to delete commission. Please try again.');
        console.error('Error deleting commission:', error);
      });
  };

  const handleSaveCommission = () => {
    const payload = {
      ...commissionFormValues,
      empNo: selectedPerson.userid
    };

    if (isCommissionEditMode) {
      updateUsersCommissions(commissionFormValues.id, payload)
        .then(() => {
          toast.success('Commission updated successfully!');
          fetchCommissions();
          setCommissionModal(false);
        })
        .catch(error => {
          toast.error('Failed to update commission. Please try again.');
        });
    } else {
      createUsersCommissions(payload)
        .then(() => {
          toast.success('Commission added successfully!');
          fetchCommissions();
          setCommissionModal(false);
        })
        .catch(error => {
          toast.error('Failed to add commission. Please try again.');
        });
    }
  };

  // Existing state
  const [assetModal, setAssetModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const [AssetsformValues, setAssetsformValues] = useState({
    userid: '',
    asset_name: '',
    holding_date: '',
    dropping_date: '',
    notes: ''
  })
  const [isAssetsEditMode, setIsAssetsEditMode] = useState(false);

  useEffect(() => {
    if (selectedPerson) {
      setFormValues({
        id: selectedPerson.id || '',
        name: selectedPerson.name || '',
        employment_number: selectedPerson.employment_number || '',
        userid: selectedPerson.userid || '',
        img: selectedPerson.img || '',
        section: selectedPerson.section || '',
        gender: selectedPerson.gender || '',
        birth_date: selectedPerson.birth_date || '',
        marital_status: selectedPerson.marital_status || '',
        nationality: selectedPerson.nationality || '',
        national_id: selectedPerson.national_id || '',
        email: selectedPerson.email || '',
        mobile_number: selectedPerson.mobile_number || '',
        phone_no_ext: selectedPerson.phone_no_ext || '',
        job_title: selectedPerson.job_title || '',
        branch: selectedPerson.branch || '',
        work_type: selectedPerson.work_type || '',
        work_location: selectedPerson.work_location || '',
        direct_manager: selectedPerson.direct_manager || '',
        department: selectedPerson.department || '',
        hiring_date: selectedPerson.hiring_date || '',
        end_of_probation: selectedPerson.end_of_probation || ''
      });
    }
  }, [selectedPerson]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    localStorage.removeItem('selectedDepartment');
    localStorage.removeItem('selectedPerson');
  }, []);


  useEffect(() => {
    UsersPersonalDetail()
      .then(res => {
        const departmentData = {
          SALES: res.filter(person => person.department === "SALES"),
          MARKETING: res.filter(person => person.department === "MARKETING"),
          IT: res.filter(person => person.department === "IT"),
          DEALING: res.filter(person => person.department === "DEALING"),
          COMPLIANCE: res.filter(person => person.department === "COMPLIANCE"),
          FINANCE: res.filter(person => person.department === "FINANCE"),
          MANAGEMENT: res.filter(person => person.department === "MANAGEMENT"),
          HR: res.filter(person => person.department === "HR"),
          SUPPORT: res.filter(
            person =>
              ![
                "SALES",
                "MARKETING",
                "IT",
                "DEALING",
                "COMPLIANCE",
                "FINANCE",
                "MANAGEMENT",
                "HR"
              ].includes(person.department)
          )
        };
        setPersonData(departmentData);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedPerson]);

  const loadData = async () => {
    if (selectedPerson) {
      try {
        const accounts = await UsersBankAccounts();
        const filteredAccounts = accounts.filter(account => account.userid === selectedPerson.userid);
        setBankAccounts(filteredAccounts);

        const holidays = await UsersTimeOff();
        const filteredTimeoff = holidays.filter(holidays => holidays.userid === selectedPerson.userid);
        setTimeOff(filteredTimeoff);

        const familyDetails = await UsersFamilyDetail();
        const filteredFamilyDetails = familyDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setFamilyDetails(filteredFamilyDetails);


        try {
          const salaryData = await fetchemployeeS();
          console.log('Salary:', salaryData);
          const filteredSalary = salaryData.rows.filter(salary => salary.empNo.toString() === selectedPerson.userid.toString()); setSalaryDetails(filteredSalary);
        } catch (error) {
          console.error('Error fetching salary details:', error);
          setSalaryDetails([]); // Handle gracefully
        }


        try {
          const assets = await fetchUsersAssets();
          console.log('Fetched assets:', assets);

          if (assets && Array.isArray(assets.rows)) {
            const filteredAssets = assets.rows.filter(asset => asset.userid === selectedPerson.userid);
            setAssets(filteredAssets);
          } else {
            console.error('Assets.rows is not an array:', assets);
            setAssets([]); // Handle gracefully if rows is not an array
          }
        } catch (error) {
          console.error('Error fetching assets:', error);
          setAssets([]); // Handle error case
        }
        const insuranceDetails = await UsersInsurances();
        const filteredInsurances = insuranceDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setInsurances(filteredInsurances);

        
            try {
              const allTicketAllowances = await UsersTicketAllowances();
              console.log("Fetched Ticket Allowances:", allTicketAllowances);

              const filteredTicketAllowances = allTicketAllowances.filter(
                (detail) => detail.userid.toString() === selectedPerson.userid.toString()
              );

              console.log("Filtered Ticket Allowances for Employee:", filteredTicketAllowances);
              setTicketAllowances(filteredTicketAllowances);
            } catch (error) {
              console.error("Error fetching ticket allowances:", error);
              setTicketAllowances([]); // Prevent errors
            }
          
        

        const contractDetails = await UsersContracts();
        const filteredContracts = contractDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setContracts(filteredContracts);

        const warningDetails = await UsersWarning();
        const filteredWarnings = warningDetails.filter(detail => detail.userid.toString() === selectedPerson.userid.toString());
        setWarnings(filteredWarnings);

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response) {
          console.error('Server Response Error:', error.response.data);
        } else if (error.request) {
          console.error('No Response Error:', error.request);
        } else {
          console.error('General Error:', error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      localStorage.setItem("selectedDepartment", selectedDepartment);
    }
  }, [selectedDepartment]);
  useEffect(() => {
    localStorage.setItem('selectedPerson', JSON.stringify(selectedPerson));
  }, [selectedPerson]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);



  const getUrl = async (personId) => {
    try {
      const res = await getDocumentSas(personId);
      console.log(`Response from getDocumentSas for user ${personId}:`, res);

      if (res && res.url) {
        return res.url; // Return the image URL
      } else {
        toast.error(`Failed to generate image link for user ${personId}`);
        return null;
      }
    } catch (err) {
      console.error(`Error generating image link for user ${personId}:`, err);
      return null;
    }
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const res = await UsersPersonalDetail(); // Fetch user details
        const departmentData = {
          SALES: [],
          MARKETING: [],
          IT: [],
          DEALING: [],
          COMPLIANCE: [],
          FINANCE: [],
          MANAGEMENT: [],
          HR: [],
          SUPPORT: [],
        };

        // Fetch and attach images for each user
        for (const person of res) {
          const imageUrl = await getUrl(person.id); // Fetch image for each user
          const updatedPerson = { ...person, profileImage: imageUrl || '' }; // Store image in person object

          // Assign to department
          if (departmentData[person.department]) {
            departmentData[person.department].push(updatedPerson);
          } else {
            departmentData.SUPPORT.push(updatedPerson);
          }
        }

        setPersonData(departmentData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchAllUsers();
  }, []); // Runs once when the component mounts
  useEffect(() => {
    const fetchProfileImage = async () => {
      if (selectedPerson && selectedPerson.id) {
        const url = await getUrl(selectedPerson.id);
        if (url) {
          setProfileImage(url); // Update profileImage state
        }
      }
    };

    fetchProfileImage();
  }, [selectedPerson]); // Runs every time selectedPerson changes


  const handleCardClick = (department) => {
    console.log("Selected Department:", department); // Debugging
    setSelectedDepartment(department);
  };


  const handlePersonClick = async (person) => {
    setSelectedPerson(null); // Force a re-render before updating

    const imageUrl = await getUrl(person.id); // Fetch the profile image
    const updatedPerson = { ...person, profileImage: imageUrl || '' }; // Attach the fetched image

    setSelectedPerson(updatedPerson); // Set the updated user
    setProfileImage(imageUrl || ''); // Ensure the profile tab uses the correct image

    // Update form values as well
    setFormValues({

      name: updatedPerson.name || '',
      employment_number: updatedPerson.employment_number || '',
      userid: updatedPerson.userid || '',
      img: updatedPerson.profileImage || '',
      gender: updatedPerson.gender || '',
      birth_date: updatedPerson.birth_date || '',
      marital_status: updatedPerson.marital_status || '',
      nationality: updatedPerson.nationality || '',
      national_id: updatedPerson.national_id || '',
      email: updatedPerson.email || '',
      mobile_number: updatedPerson.mobile_number || '',
      phone_no_ext: updatedPerson.phone_no_ext || '',
      job_title: updatedPerson.job_title || '',
      section: updatedPerson.section || '',
      branch: updatedPerson.branch || '',
      work_type: updatedPerson.work_type || '',
      work_location: updatedPerson.work_location || '',
      direct_manager: updatedPerson.direct_manager || '',
      department: updatedPerson.department || '',
      hiring_date: updatedPerson.hiring_date || '',
      end_of_probation: updatedPerson.end_of_probation || ''
    });
  };


  const handleBackClick = () => {
    if (selectedPerson) {
      setSelectedPerson(null);
      setActiveTab('Profile');
    } else {
      setSelectedDepartment(null);
    }
  };

  const toggleUserInfoModal = () => setUserInfoModal(!userInfoModal);
  const togglePersonalInfoModal = () => setPersonalInfoModal(!personalInfoModal);
  const toggleContactInfoModal = () => setContactInfoModal(!contactInfoModal);
  const toggleProfessionalInfoModal = () => setProfessionalInfoModal(!professionalInfoModal);
  const toggleAddUserModal = () => setAddUserModal(!addUserModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleBankAccountModal = () => setBankAccountModal(!bankAccountModal);
  const toggleInsuranceModal = () => setInsuranceModal(!insuranceModal);
  const toggleAssetModal = () => setAssetModal(!assetModal); // Add this line
  const toggleTicketAllowanceModal = () => setTicketAllowanceModal(!ticketAllowanceModal);
  const toggleContractModal = () => setContractModal(!contractModal);
  const toggleWarningModal = () => setWarningModal(!warningModal);
  const toggleTimeOffModal = () => setTimeOffModal(!timeOffModal);
  const toggleImgModal = () => setAddImgModal(!addImgModal);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBankAccountFormChange = (e) => {
    const { name, value } = e.target;
    setBankAccountFormValues({ ...bankAccountFormValues, [name]: value });
  };

  const handleTimeOffFormChange = (e) => {
    const { name, value } = e.target;
    setTimeOffFormValues({ ...timeOffFormValues, [name]: value });
  }

  const handleAssetsFormChange = (e) => {
    const { name, value } = e.target;
    setAssetsformValues({ ...AssetsformValues, [name]: value });
  };


  const handleInsuranceFormChange = (e) => {
    const { name, value } = e.target;
    setInsuranceFormValues({ ...insuranceFormValues, [name]: value });
  };

  const handleTicketAllowanceFormChange = (e) => {
    const { name, value } = e.target;
    setTicketAllowanceFormValues({ ...ticketAllowanceFormValues, [name]: value });
  };

  const handleContractFormChange = (e) => {
    const { name, value } = e.target;
    setContractFormValues({ ...contractFormValues, [name]: value });
  };

  const handleWarningFormChange = (e) => {
    const { name, value } = e.target;
    setWarningFormValues({ ...warningFormValues, [name]: value });
  };




  const handleSaveUserInfo = () => {
    if (!selectedPerson) return;

    const formData = new FormData();
    console.log('Form values:', formValues);

    formData.append('userid', selectedPerson.userid);  // Ensure userid is added
    formData.append('name', formValues.name);  // Add name
    formData.append('gender', formValues.gender);  // Add gender
    formData.append('img', formValues.img);  // Add img file or URL
    formData.append('section', formValues.section);

    // Add any other fields from formValues (adjust this according to the fields you have)
    formData.append('work_location', formValues.work_location);
    formData.append('department', formValues.department);
    formData.append('section', formValues.section);
    formData.append('employment_number', formValues.employment_number);
    formData.append('job_title', formValues.job_title);
    formData.append('work_type', formValues.work_type);
    formData.append('direct_manager', formValues.direct_manager);
    formData.append('branch', formValues.branch);
    formData.append('hiring_date', formValues.hiring_date);
    formData.append('end_of_probation', formValues.end_of_probation);
    formData.append('birth_date', formValues.birth_date);
    formData.append('marital_status', formValues.marital_status);
    formData.append('nationality', formValues.nationality);
    formData.append('national_id', formValues.national_id);
    formData.append('email', formValues.email);
    formData.append('mobile_number', formValues.mobile_number);
    formData.append('phone_no_ext', formValues.phone_no_ext);

    updateUsersPersonalDetail(selectedPerson.id, formData) // Adjust your API call to accept FormData
      .then(response => {
        setSelectedPerson({ ...selectedPerson, ...formValues });
        setPersonData(prevData => {
          const updatedData = { ...prevData };
          const departments = ['SALES', 'MARKETING', 'IT', 'COMPLIANCE', 'DEALING', 'HR', 'MANAGEMENT', 'FINANCE', 'SUPPORT'];

          departments.forEach(department => {
            if (selectedPerson.department === department) {
              updatedData[department] = updatedData[department].map(person =>
                person.employment_number === formValues.employment_number ? { ...formValues } : person
              );
            }
          });

          return updatedData;
        });

        if (userInfoModal) toggleUserInfoModal();
        if (personalInfoModal) togglePersonalInfoModal();
        if (contactInfoModal) toggleContactInfoModal();
        if (professionalInfoModal) toggleProfessionalInfoModal();
        if (addImgModal) toggleAddUserModal();
        toast.success("User information updated successfully!");
      })
      .catch(error => {
        console.error("Error updating user information:", error);
        toast.error("Failed to update user information. Please try again.");
      });
  };


  const handleDeleteClick = (person) => {
    setPersonToDelete(person);
    toggleDeleteModal();
  };

  const confirmDelete = () => {
    if (!personToDelete) return;

    deleteUsersPersonalDetail(personToDelete.id)
      .then(() => {
        toast.success('User deleted successfully!');
        toggleDeleteModal();
        setPersonData(prevData => {
          const updatedData = { ...prevData };
          const departments = ['SALES', 'MARKETING', 'IT', 'COMPLIANCE', 'DEALING', 'HR', 'MANAGEMENT', 'FINANCE', 'SUPPORT'];

          departments.forEach(department => {
            if (personToDelete.department === department) {
              updatedData[department] = updatedData[department].filter(person => person.id !== personToDelete.id);
            }
          });

          return updatedData;
        });
        setSelectedPerson(null);
      })
      .catch(error => {
        toast.error('Failed to delete user. Please try again.');
        console.error('Error deleting user:', error);
      });
  };



  const handleAddBankAccount = () => {
    setIsEditMode(false);
    setBankAccountFormValues({
      bank_name: '',
      branch: '',
      account_number: '',
      iban: '',
      SWIFTcode: ''
    });
    toggleBankAccountModal();
  };

  const handleEditBankAccount = (account) => {
    setIsEditMode(true);
    setBankAccountFormValues(account);
    toggleBankAccountModal();
  };

  const handleDeleteBankAccount = (accountId) => {
    deleteUsersBankAccounts(accountId)
      .then(() => {
        toast.success('Bank account deleted successfully!');
        setBankAccounts(prevAccounts => prevAccounts.filter(account => account.id !== accountId));
      })
      .catch(error => {
        toast.error('Failed to delete bank account. Please try again.');
        console.error('Error deleting bank account:', error);
      });
  };

  const handleSaveBankAccount = () => {
    const payload = {
      ...bankAccountFormValues,
      userid: selectedPerson.userid
    };

    if (isEditMode) {
      updateUsersBankAccounts(bankAccountFormValues.id, payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Bank account updated successfully!');
            loadData();
            setBankAccounts(prevAccounts => prevAccounts.map(account =>
              account.id === bankAccountFormValues.id ? bankAccountFormValues : account
            ));
            toggleBankAccountModal();
          } else {
            toast.error('Failed to update bank account.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to update bank account. Please try again.');
        });
    } else {
      createUsersBankAccounts(payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Bank account added successfully!');
            loadData();
            toggleBankAccountModal();
          } else {
            toast.error('Failed to add bank account.');




          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to add bank account. Please try again.');
        });
    }
  };

  const documentUpload = (event) => {
    setFile(event.target.files[0]);
    console.log("file is changes ", event.target.files)
  };

  const handleSaveImg = async (e) => {
    e.preventDefault();

    const payload = {
      'ID': file, // Assuming the API requires the file name
    };
    try {
      let response
      // Ensure the document has an ID to proceed with update
      if (selectedPerson.id) {
        response = await addImage(selectedPerson.id, payload);
        toast.success('Document updated successfully!');
        console.log('response:', response);
      } else {
        response = await createUsersPersonalDetail(payload);
        if (response[0].id) {
          const updatedResponse = await updateUsersPersonalDetail(response[0].id, selectedPerson);
          console.log('update response: ', updatedResponse);
        }


      }
      toggleImgModal();
    } catch (error) {
      console.error('Error updating Image:', error);
      toast.error('Failed to update Image. Please try again.');
    }
  };




  //TimeOff Info Handlers
  const handleAddTimeOff = () => {
    setIsEditMode(false);
    setTimeOffFormValues({
      timeoffType: '',
      customSetup: '',
      openBalance: '',
      carryBalance: ''

    });
    toggleTimeOffModal();
  };

  const handleEditTimeOff = (holidays) => {
    setIsEditMode(true);
    setTimeOffFormValues(holidays);
    toggleTimeOffModal();
  };

  const handleDeleteTimeOff = (holidaysId) => {
    deleteUsersTimeOff(holidaysId)
      .then(() => {
        toast.success('Time Off deleted successfully!');
        setTimeOff(prevholidays => prevholidays.filter(holidays => holidays.id !== holidaysId));
      })
      .catch(error => {
        toast.error('Failed to delete time off. Please try again.');
        console.error('Error deleting time off:', error);
      });
  };

  const handleSaveTimOff = () => {
    const payload = {
      ...timeOffFormValues,
      userid: selectedPerson.userid
    };

    if (isEditMode) {
      updateUsersTimeOff(timeOff.id, payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Time Off updated successfully!');
            loadData();
            setTimeOff(prevholidays => prevholidays.map(holidays =>
              holidays.id === timeOffFormValues.id ? timeOffFormValues : holidays
            ));
            toggleTimeOffModal();
          } else {
            toast.error('Failed to update  time off.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to update timr off. Please try again.');
        });
    } else {
      createUsersTimeOff(payload)
        .then((response) => {
          if (response.length !== 0) {
            toast.success('Time off added successfully!');
            loadData();
            toggleTimeOffModal();
          } else {
            toast.error('Failed to add time off.');




          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Failed to add time off. Please try again.');
        });
    }
  };
  // Emergency Contact Details Handlers
  const handleAddFamilyDetail = () => {
    setIsFamilyEditMode(false);
    setFamilyFormValues({
      employeeName: '',
      nationality: '',
      homeAddress: '',
      homeEcontactName: '',
      homeEcontactNumber: '',
      UAEaddress: '',
      UAEeContactName: '',
      UAEeContactNumber: '',
      UAEpNumber: '',
      homePnumber: ''
    });
    setFamilyDetailModal(true);
  };

  const handleEditFamilyDetail = (detail) => {
    setIsFamilyEditMode(true);
    setFamilyFormValues(detail);
    setFamilyDetailModal(true);
  };

  const handleDeleteFamilyDetail = (id) => {
    deleteUsersFamilyDetail(id)
      .then(() => {
        toast.success('Family detail deleted successfully!');
        setFamilyDetails(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete family detail. Please try again.');
        console.error('Error deleting family detail:', error);
      });
  };

  const handleSaveFamilyDetail = () => {
    const payload = {
      ...familyFormValues,
      userid: selectedPerson.userid
    };

    if (isFamilyEditMode) {
      updateUsersFamilyDetail(familyFormValues.id, payload)
        .then(() => {
          toast.success('Family detail updated successfully!');
          loadData();
          setFamilyDetailModal(false);
        })
        .catch(error => {
          console.error('Error updating family detail:', error);
          toast.error('Failed to update family detail. Please try again.');
        });
    } else {
      createUsersFamilyDetail(payload)
        .then(() => {
          toast.success('Family detail added successfully!');
          loadData();
          setFamilyDetailModal(false);
        })
        .catch(error => {
          console.error('Error adding family detail:', error);
          toast.error('Failed to add family detail. Please try again.');
        });
    }
  };
  // Asset Info Handlers

  // Handles form input changes

  // Initializes the form for creating a new assets
  const initializeCreateAssetForm = () => {
    setIsAssetsEditMode(false);
    setAssetsformValues({
      userid: '',
      asset_name: '',
      holding_date: '',
      dropping_date: '',
      notes: ''
    });
    setAssetModal(true); // Opens the modal
  };

  // Initializes the form for updating an existing assets
  const initializeUpdateAssetForm = (assets) => {
    setIsAssetsEditMode(true);
    setAssetsformValues(assets);
    toggleAssetModal(true); // Opens the modal
  };

  // Deletes a user assets by ID
  const handleDeleteUsersAssets = (id) => {
    deleteUsersAssets(id)
      .then(() => {
        toast.success('Asset deleted successfully');
        setAssets(prevAssets => prevAssets.filter(assets => assets.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete assets. Please try again.');
        console.error('Error deleting assets:', error);
      });

  };

  // Handles saving the assets (create or update)
  const handleSaveAsset = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!AssetsformValues.asset_name || !AssetsformValues.holding_date) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const payload = {
      ...AssetsformValues,
      userid: selectedPerson.userid
    };

    try {
      if (isAssetsEditMode) {
        // Call the actual update API function
        await updateUsersAssets(AssetsformValues.id, payload);
        toast.success('Asset updated successfully!');
      } else {
        // Call the actual create API function
        await createUsersAssets(payload);
        toast.success('Asset added successfully!');
      }

      loadData();

      // Close the assets modal after saving
      toggleAssetModal();
    } catch (error) {
      console.error('Error saving assets:', error);
      toast.error('Failed to save assets. Please try again.');
    }
  };

  // Insurance Info Handlers
  const handleAddInsuranceDetail = () => {
    setIsInsuranceEditMode(false);
    setInsuranceFormValues({
      employeeName: '',
      insurerName: '',
      startDate: '',
      endDate: '',
      deduction: ''
    });
    toggleInsuranceModal();
  };

  const handleEditInsuranceDetail = (detail) => {
    setIsInsuranceEditMode(true);
    setInsuranceFormValues(detail);
    toggleInsuranceModal();
  };

  const handleDeleteInsuranceDetail = (id) => {
    deleteUsersInsurances(id)
      .then(() => {
        toast.success('Insurance detail deleted successfully!');
        setInsurances(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete insurance detail. Please try again.');
        console.error('Error deleting insurance detail:', error);
      });
  };

  const handleSaveInsuranceDetail = () => {
    const payload = {
      ...insuranceFormValues,
      userid: selectedPerson.userid
    };

    if (isInsuranceEditMode) {
      updateUsersInsurances(insuranceFormValues.id, payload)
        .then(() => {
          toast.success('Insurance detail updated successfully!');
          loadData();
          toggleInsuranceModal(false);
        })
        .catch(error => {
          console.error('Error updating insurance detail:', error);
          toast.error('Failed to update insurance detail. Please try again.');
        });
    } else {
      createUsersInsurances(payload)
        .then(() => {
          toast.success('Insurance detail added successfully!');
          loadData();
          toggleInsuranceModal(false);
        })
        .catch(error => {
          console.error('Error adding insurance detail:', error);
          toast.error('Failed to add insurance detail. Please try again.');
        });
    }
  };

  // Ticket Allowance Info Handlers
  const handleAddTicketAllowanceDetail = () => {
    if (!selectedPerson || !selectedPerson.hiring_date) {
      toast.error("Hiring date is missing for this employee.");
      return;
    }

    // Calculate next eligible allowance date
    const hiringDate = new Date(selectedPerson.hiring_date);
    const today = new Date();
    let nextAllowanceDate = new Date(hiringDate);

    if (nextAllowanceDate.getTime() === today.getTime()) {
      nextAllowanceDate.setFullYear(nextAllowanceDate.getFullYear() + 1);
    }


    setIsTicketAllowanceEditMode(false);
    setTicketAllowanceFormValues({
      employeeName: selectedPerson.name,
      transactionType: '1500 AED',
      date: nextAllowanceDate.toISOString().split('T')[0], // Convert to YYYY-MM-DD format
      active: new Date(nextAllowanceDate) <= new Date()
      , // Initially inactive
    });

    toggleTicketAllowanceModal();
  };
  const handleEditTicketAllowanceDetail = (detail) => {
    setIsTicketAllowanceEditMode(true);
    setTicketAllowanceFormValues(detail);
    toggleTicketAllowanceModal();
  };

  const handleDeleteTicketAllowanceDetail = (id) => {
    deleteUsersTicketAllowances(id)
      .then(() => {
        toast.success('Ticket Allowance detail deleted successfully!');
        setTicketAllowances(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete Ticket Allowance detail. Please try again.');
        console.error('Error deleting Ticket Allowance detail:', error);
      });
  };

  const handleSaveTicketAllowanceDetail = () => {
    const payload = {
      ...ticketAllowanceFormValues,
      userid: selectedPerson.userid
    };

    if (isTicketAllowanceEditMode) {
      updateUsersTicketAllowances(ticketAllowanceFormValues.id, payload)
        .then(() => {
          toast.success('Ticket Allowance detail updated successfully!');
          loadData();
          toggleTicketAllowanceModal(false);
        })
        .catch(error => {
          console.error('Error updating Ticket Allowance detail:', error);
          toast.error('Failed to update Ticket Allowance detail. Please try again.');
        });
    } else {
      createUsersTicketAllowances(payload)
        .then(() => {
          toast.success('Ticket Allowance detail added successfully!');
          loadData();
          toggleTicketAllowanceModal(false);
        })
        .catch(error => {
          console.error('Error adding Ticket Allowance detail:', error);
          toast.error('Failed to add Ticket Allowance detail. Please try again.');
        });
    }
  };

  // Contract Info Handlers
  const handleAddContractDetail = () => {
    setIsContractEditMode(false);
    setContractFormValues({
      contractName: '',
      startDate: '',
      endDate: '',
      contractType: ''
    });
    toggleContractModal();
  };

  const handleEditContractDetail = (detail) => {
    setIsContractEditMode(true);
    setContractFormValues(detail);
    toggleContractModal();
  };

  const handleDeleteContractDetail = (id) => {
    deleteUsersContracts(id)
      .then(() => {
        toast.success('Contract detail deleted successfully!');
        setContracts(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete contract detail. Please try again.');
        console.error('Error deleting contract detail:', error);
      });
  };

  const handleSaveContractDetail = () => {
    const payload = {
      ...contractFormValues,
      userid: selectedPerson.userid
    };

    if (isContractEditMode) {
      updateUsersContracts(contractFormValues.id, payload)
        .then(() => {
          toast.success('Contract detail updated successfully!');
          loadData();
          toggleContractModal(false);
        })
        .catch(error => {
          console.error('Error updating contract detail:', error);
          toast.error('Failed to update contract detail. Please try again.');
        });
    } else {
      createUsersContracts(payload)
        .then(() => {
          toast.success('Contract detail added successfully!');
          loadData();
          toggleContractModal(false);
        })
        .catch(error => {
          console.error('Error adding contract detail:', error);
          toast.error('Failed to add contract detail. Please try again.');
        });
    }
  };



  // Warning Info Handlers
  const handleAddWarningDetail = () => {
    setIsWarningEditMode(false);
    setWarningFormValues({
      reason: '',
      managementfile: '',
      submitionfile: '',
      Date: ''
    });
    toggleWarningModal();
  };

  const handleEditWarningDetail = (detail) => {
    setIsWarningEditMode(true);
    setWarningFormValues(detail);
    toggleWarningModal();
  };

  const handleDeleteWarningDetail = (id) => {
    deleteUsersWarning(id)
      .then(() => {
        toast.success('Warning detail deleted successfully!');
        setWarnings(prevDetails => prevDetails.filter(detail => detail.id !== id));
      })
      .catch(error => {
        toast.error('Failed to delete warning detail. Please try again.');
        console.error('Error deleting warning detail:', error);
      });
  };

  const handleSaveWarningDetail = () => {
    const payload = {
      ...warningFormValues,
      userid: selectedPerson.userid, // Ensure userid is set here
    };

    if (isWarningEditMode) {
      updateUsersWarning(warningFormValues.id, payload)
        .then(() => {
          toast.success('Warning detail updated successfully!');
          loadData();
          toggleWarningModal(false);
        })
        .catch(error => {
          console.error('Error updating warning detail:', error);
          toast.error('Failed to update warning detail. Please try again.');
        });
    } else {
      createUsersWarning(payload)
        .then(() => {
          toast.success('Warning detail added successfully!');
          loadData();
          toggleWarningModal(false);
        })
        .catch(error => {
          console.error('Error adding warning detail:', error);
          toast.error('Failed to add warning detail. Please try again.');
        });
    }
  };


  const calculatePeriodOfEmployment = (hiringDate) => {
    const startDate = new Date(hiringDate);
    const currentDate = new Date();

    const totalMonths = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + currentDate.getMonth() - startDate.getMonth();
    const totalDays = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const weeks = Math.floor((totalDays % 365 % 30) / 7);
    const days = totalDays % 365 % 30 % 7;

    return `${years} years, ${months} months, ${weeks} weeks, ${days} days`;
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }

    return age;
  };

  const renderPersons = (department) => {
    const persons = personData[department] || [];

    return (
      <div className="parent-card">
        <div className="header-container">
          <ArrowLeft className="back-icon" onClick={handleBackClick} />
          <h2 className="department-title">{department}</h2>
          <Button color="primary" className="add-button" onClick={toggleAddUserModal}> <PlusCircle size={18} /></Button>
        </div>
        <div className="persons-container">
          {persons.length > 0 ? (
            persons.map((person, index) => (
              <div key={index} className="person-card" onClick={() => handlePersonClick(person)}>
                <div className="company-name">GoDo</div>
                <div className="person-img-container">
                  {person.profileImage ? (
                    <img
                      src={person.profileImage} // Dynamically fetched image
                      alt={`${person.name}'s profile`}
                      className="person-img-medium"
                    />
                  ) : (
                    <p>Loading image...</p>
                  )}
                </div>
                <div className="person-details">
                  <div className="person-name">{person.name}</div>
                  <div className="person-designation">{person.job_title}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-persons">No persons available in {department}.</div>
          )}
        </div>
        <style>
          {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
        </style>
      </div>
    );
  };


  const renderPersonDetails = () => {
    const tabs = [
      'Profile',
      'Bank Accounts',
      'Emergency Contact Details',
      'Documents',
      'Insurance',
      'Air Ticket Allowances',
      'Timeoff Setup',
      'Assets',
      'Contracts',
      'Salary',
      'Commissions',
      // 'Off-Boarding',
      // 'Commission',
      // 'Warning'
    ];

    const renderTabContent = () => {
      switch (activeTab) {
        case 'Profile':
          return (
            <div className="tab-contentt">
              <div className="section user-info-card">
                <div className="section-header">
                  <h3 className="section-title">User Info</h3>
                  <Edit size={18} onClick={toggleUserInfoModal} style={{ cursor: 'pointer' }} />


                  <Upload size={18} onClick={toggleImgModal} style={{ cursor: 'pointer' }} />
                </div>

                <div className="section-content">
                  {selectedPerson.profileImage ? (
                    <img
                      src={selectedPerson.profileImage}
                      alt={`${selectedPerson.name}'s profile`}
                      className="person-img-medium"
                    />
                  ) : (
                    <p>Loading image...</p>
                  )}
                  <div className="user-info">
                    <p><strong>Name: </strong>{selectedPerson.name}</p>
                    <p><strong>Employment Number: </strong>{selectedPerson.employment_number}</p>
                    <p><strong>User Support ID: </strong>{selectedPerson.userid}</p>
                  </div>
                </div>
              </div>

              <div className="section personal-info-card">
                <div className="section-header">
                  <h3 className="section-title">Personal Info</h3>
                  <Edit size={18} onClick={togglePersonalInfoModal} style={{ cursor: 'pointer' }} />

                </div>
                <div className="section-content">
                  <p><strong>Gender: </strong>{selectedPerson.gender}</p>
                  <p><strong>Birth Date: </strong>{selectedPerson.birth_date}</p>
                  <p><strong>Age: </strong>{calculateAge(selectedPerson.birth_date)}</p>
                  <p><strong>Marital Status: </strong>{selectedPerson.marital_status}</p>
                  <p><strong>Nationality: </strong>{selectedPerson.nationality}</p>
                  <p><strong>National ID: </strong>{selectedPerson.national_id}</p>
                </div>
              </div>

              <div className="section contact-info-card">
                <div className="section-header">
                  <h3 className="section-title">Contact Info</h3>
                  <Edit size={18} onClick={toggleContactInfoModal} style={{ cursor: 'pointer' }} />

                </div>
                <div className="section-content">
                  <p><strong>Email: </strong>{selectedPerson.email}</p>
                  <p><strong>Mobile Number: </strong>{selectedPerson.mobile_number}</p>
                  <p><strong>Phone No. And Ext: </strong>{selectedPerson.phone_no_ext}</p>
                </div>
              </div>

              <div className="section professional-info-card">
                <div className="section-header">
                  <h3 className="section-title">Professional Info</h3>
                  <Edit size={18} onClick={toggleProfessionalInfoModal} style={{ cursor: 'pointer' }} />

                </div>
                <div className="section-content">
                  <p><strong>Job Title: </strong>{selectedPerson.job_title}</p>
                  <p><strong>Branch: </strong>{selectedPerson.branch}</p>
                  <p><strong>Work Type: </strong>{selectedPerson.work_type}</p>
                  <p><strong>Work Location: </strong>{selectedPerson.work_location}</p>
                  <p><strong>Direct Manager: </strong>{selectedPerson.direct_manager}</p>
                  <p><strong>Department: </strong>{selectedPerson.department}</p>
                  <p><strong>Hiring Date: </strong>{selectedPerson.hiring_date}</p>
                  <p><strong>End of Probation: </strong>{selectedPerson.end_of_probation}</p>
                  <p><strong>Period of Employment: </strong>{calculatePeriodOfEmployment(selectedPerson.hiring_date)}</p>
                </div>
              </div>

              <div className="section timeoff-balance-card">
                <h3 className="section-title">Timeoff Balances</h3>
                <div className="section-content timeoff-balances">
                  <div className="balance-item">
                    <h4>Sick Day off</h4>
                    <p><strong>Available Balance: </strong>{selectedPerson.sick_days_available}</p>
                    <p><strong>Up to end of year: </strong>{selectedPerson.sick_days_end_of_year}</p>
                  </div>
                  <div className="balance-item">
                    <h4>Annual Vacations</h4>
                    <p><strong>Available Balance: </strong>{selectedPerson.annual_vacations_available}</p>
                    <p><strong>Up to end of year: </strong>{selectedPerson.annual_vacations_end_of_year}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        case 'Bank Accounts':
          return (
            <div className="tab-contentt">
              <div className="section bank-accounts-card">
                <div className="section-header">
                  <h3 className="section-title">Bank Accounts</h3>
                  <PlusCircle size={18} onClick={handleAddBankAccount} style={{ cursor: 'pointer' }} />                </div>
                <table className="bank-accounts-table">
                  <thead>
                    <tr>
                      <th>Bank Name</th>
                      <th>Branch</th>
                      <th>Account Number</th>
                      <th>IBAN</th>
                      <th>SWIFT Code</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankAccounts.map((account) => (
                      <tr key={account.id}> {/* Ensure each tr has a unique key */}
                        <td>{account.bank_name}</td>
                        <td>{account.branch}</td>
                        <td>{account.account_number}</td>
                        <td>{account.iban}</td>
                        <td>{account.SWIFTcode}</td>
                        <td>
                          <Edit size={18} onClick={() => handleEditBankAccount(account)} style={{ cursor: 'pointer' }} />                        </td>
                        <td>
                          <Trash size={18} onClick={() => handleDeleteBankAccount(account.id)} style={{ cursor: 'pointer', color: 'red' }} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {bankAccounts.length} results</p>
              </div>
            </div>
          );
        case 'Emergency Contact Details':
          return (
            <div className="tab-contentt">
              <div className="section family-info-card">
                <div className="section-header d-flex justify-content-between align-items-center">
                  <h3 className="section-title">Emergency Contact Details</h3>
                  <PlusCircle size={20} onClick={handleAddFamilyDetail} style={{ cursor: 'pointer' }} />
                </div>

                {familyDetails.length > 0 ? (
                  <div className="single-family-card border rounded shadow-sm p-4 bg-light">


                    {/* Employee Info */}
                    <div className="family-info-row">
                      <p><strong>Employee Name:</strong> {selectedPerson.name}</p>
                      <p><strong>Nationality:</strong> {selectedPerson.nationality}</p>
                    </div>

                    <hr />

                    {/* Home Contact */}
                    <h5 className="text-dark mt-3">Home Contact</h5>
                    <div className="family-info-row">
                      <p><strong>Address:</strong> {familyDetails[0].homeAddress}</p>
                      <p><strong>Contact Person Name :</strong> {familyDetails[0].homeEcontactName}</p>
                      <p><strong>Phone:</strong> {familyDetails[0].homeEcontactNumber}</p>
                      <p><strong>Personal Number:</strong> {familyDetails[0].homePnumber}</p>
                    </div>

                    <hr />

                    {/* UAE Contact */}
                    <h5 className="text-dark mt-3">UAE Contact</h5>
                    <div className="family-info-row">
                      <p><strong>Address:</strong> {familyDetails[0].UAEaddress}</p>
                      <p><strong>Contact Person Name:</strong> {familyDetails[0].UAEeContactName}</p>
                      <p><strong>Phone:</strong> {familyDetails[0].UAEeContactNumber}</p>
                      <p><strong>Personal Number:</strong> {familyDetails[0].UAEpNumber}</p>
                    </div>

                    {/* Action Buttons */}
                    <div className="actions d-flex justify-content-end gap-3 mt-3">
                      <Edit size={20} onClick={() => handleEditFamilyDetail(familyDetails[0])} style={{ cursor: 'pointer', color: 'black' }} />
                      <Trash size={20} onClick={() => handleDeleteFamilyDetail(familyDetails[0].id)} style={{ cursor: 'pointer', color: 'red' }} />
                    </div>
                  </div>
                ) : (
                  <p className="text-muted text-center py-4">No emergency contacts available.</p>
                )}
              </div>
            </div>
          );

        case 'Documents':
          return (
            <div className="tab-contentt">
              <div className="section documents-card">
                {/* Header Section */}
                <div className="section-header d-flex justify-content-between align-items-center border-bottom pb-3 mb-4">
                  <h3 className="section-title text-primary font-weight-bold">
                    Documents for {selectedPerson.name}
                  </h3>

                </div>

                {/* Documents List */}
                {documents.length > 0 ? (
                  <div className="d-flex flex-wrap gap-4">
                    {documents.map((doc, index) => (
                      <div key={index} className="document-card border rounded shadow-sm p-4 bg-light w-100">
                        {/* Document Title */}
                        <h5 className="text-dark font-weight-bold mb-2">
                          {doc.docType} - {doc.docName}
                        </h5>

                        {/* Document Details */}
                        <p className="mb-1">
                          <strong className="text-secondary">Owner:</strong> {doc.owner}
                        </p>
                        <p className="mb-1">
                          <strong className="text-secondary">Company Doc:</strong> {doc.companydoc}
                        </p>
                        <p className="mb-1">
                          <strong className="text-secondary">Expiry Date:</strong> {doc.expiryDate}
                        </p>
                        <p className="mb-3">
                          <strong className="text-secondary">Created At:</strong> {doc.createdAt}
                        </p>

                        {/* View Document Button */}
                        {doc.documentUrl ? (
                          <Eye size={18} onClick={() => getDUrl(doc.id)} style={{ cursor: 'pointer', color: 'green' }} />
                        ) : (
                          <a
                            href={doc.documentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary font-weight-bold"
                          >
                            View Document
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-muted text-center py-4">
                    No documents available for {selectedPerson.name}.
                  </p>
                )}
              </div>
            </div>
          );

        case 'Insurance':
          return (
            <div className="tab-contentt">
              <div className="section insurance-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Insurances</h3>
                  <PlusCircle size={18} onClick={handleAddInsuranceDetail} style={{ cursor: 'pointer' }} />
                </div>
                <table className="insurance-table">
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Insurer Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Deduction</th>
                      <th>Active</th> {/* New Active Column */}
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insurances.map((detail) => {
                      const isActive = new Date(detail.endDate) > new Date();
                      return (
                        <tr key={detail.id}>
                          <td>{detail.employeeName}</td>
                          <td>{detail.insurerName}</td>
                          <td>{detail.startDate}</td>
                          <td>{detail.endDate}</td>
                          <td>{detail.deduction}</td>
                          <td>{isActive ? 'Yes' : 'No'}</td> {/* Display Active Status */}
                          <td>
                            <Edit size={18} onClick={() => handleEditInsuranceDetail(detail)} style={{ cursor: 'pointer' }} />
                          </td>
                          <td>
                            <Trash size={18} onClick={() => handleDeleteInsuranceDetail(detail.id)} style={{ cursor: 'pointer', color: 'red' }} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <p>Showing {insurances.length} results</p>
              </div>
            </div>
          );

        case 'Air Ticket Allowances':
          return (
            <div className="tab-contentt">
              <div className="section air-ticket-card">
                <div className="section-header">
                  <h3 className="section-title">Air Ticket Allowances</h3>
                  <PlusCircle size={18} onClick={handleAddTicketAllowanceDetail} style={{ cursor: 'pointer' }} />
                </div>
                <table className="air-ticket-table">
                  <thead>
                    <tr>
                      <th>Allowance Type</th>
                      <th>Activation Date</th>
                      <th>Status</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketAllowances

                      .map((detail) => (
                        <tr key={detail.id}>
                          <td>{detail.transactionType}</td>
                          <td>{new Date(detail.date).toLocaleDateString()}</td>
                          <td>{detail.active ? 'Active' : 'Upcoming'}</td>
                          <td>
                            <Edit size={18} onClick={() => handleEditTicketAllowanceDetail(detail)} style={{ cursor: 'pointer' }} />
                          </td>
                          <td>
                            <Trash size={18} onClick={() => handleDeleteTicketAllowanceDetail(detail.id)} style={{ cursor: 'pointer', color: 'red' }} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <p>Showing {ticketAllowances.length} results</p>
              </div>
            </div>
          );

        case 'Timeoff Setup':
          return (
            <div className="tab-contentt">
              <div className="section timeoff-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Time Offs</h3>
                  <PlusCircle size={18} onClick={handleAddTimeOff} style={{ cursor: 'pointer' }} />
                </div>
                <div className="toolbar-sear">
                  <Input type="text" className="search-input" placeholder="Search" />
                  <div className="toolbar-buttons">
                    <Input type="select" className="results-per-page">
                      <option>Results per page 10</option>
                      {/* Add more options as needed */}
                    </Input>
                  </div>
                </div>
                <table className="timeoff-table">
                  <thead>
                    <tr>
                      <th>Time Off Type</th>
                      <th>Custom Setup</th>
                      <th>Open Balance</th>
                      <th>Carry Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeOff.map((holidays) => (
                      <tr key={holidays.id}> {/* Ensure each tr has a unique key */}
                        <td>{holidays.timeoffType}</td>
                        <td>{holidays.customSetup}</td>
                        <td>{holidays.openBalance}</td>
                        <td>{holidays.carryBalance}</td>

                        <td>
                          <Edit size={18} onClick={() => handleEditTimeOff(holidays)} style={{ cursor: 'pointer' }} />
                        </td>
                        <td>
                          <Trash size={18} onClick={() => handleDeleteTimeOff(holidays.id)} style={{ cursor: 'pointer', color: 'red' }} />

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing 1 - 2 of 2 results</p>
              </div>
            </div>
          );
        case 'Assets':
          return (
            <div className="tab-contentt">
              <div className="section assets-card">
                <div className="section-header">
                  <h3 className="section-title">Assets List</h3>
                  <PlusCircle size={18} onClick={initializeCreateAssetForm} style={{ cursor: 'pointer' }} />
                </div>
                <div className="toolbar-sear">
                  <Input type="text" className="search-input" placeholder="Search" />
                  <div className="toolbar-buttons">
                    <Input type="select" className="results-per-page">
                      <option>Results per page 10</option>
                      {/* Add more options as needed */}
                    </Input>
                  </div>
                </div>
                <table className="assets-table">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Holding Date</th>
                      <th>Dropping Date</th>
                      <th>Notes</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assets.map((assets) => (
                      <tr key={assets.id}>
                        <td>{assets.asset_name}</td>
                        <td>{assets.holding_date}</td>
                        <td>{assets.dropping_date || 'N/A'}</td>
                        <td>{assets.notes || 'N/A'}</td>
                        <td>{assets.createdby || 'N/A'}</td>
                        <td>{assets.updatedby || 'N/A'}</td>
                        <td>
                          <Edit size={18} onClick={() => initializeUpdateAssetForm(assets)} style={{ cursor: 'pointer' }} />
                        </td>
                        <td>
                          <Trash size={18} onClick={() => handleDeleteUsersAssets(assets.id)} style={{ cursor: 'pointer', color: 'red' }} />

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {assets.length} results</p>
              </div>


            </div>
          );


        case 'Contracts':
          return (
            <div className="tab-contentt">
              <div className="section contracts-card">
                <div className="section-header">
                  <h3 className="section-title">Employee Contracts</h3>
                  <PlusCircle size={18} onClick={handleAddContractDetail} style={{ cursor: 'pointer' }} />
                </div>

                <table className="contracts-table">
                  <thead>
                    <tr>
                      <th>Contract Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Contract Type</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.map((detail) => (
                      <tr key={detail.id}>
                        <td>{detail.contractName}</td>
                        <td>{detail.startDate}</td>
                        <td>{detail.endDate}</td>
                        <td>{detail.contractType}</td>
                        <td>
                          <Edit size={18} onClick={() => handleEditContractDetail(detail)} style={{ cursor: 'pointer' }} />
                        </td>
                        <td>
                          <Trash size={18} onClick={() => handleDeleteContractDetail(detail.id)} style={{ cursor: 'pointer', color: 'red' }} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>Showing {contracts.length} results</p>
              </div>
            </div>
          );
        case 'Salary':
          return (
            <div className="tab-contentt">
              <div className="section salary-card">
                {/* Header Section */}
                <div className="section-header d-flex justify-content-between align-items-center border-bottom pb-3 mb-4">
                  <h3 className="section-title text-primary font-weight-bold">
                    Employee Salary Details
                  </h3>
                </div>

                {/* Salary Table */}
                <div className="table-responsive">
                  <table className="salary-table table table-hover table-bordered text-center">
                    <thead className="thead-dark">
                      <tr>
                        <th>Basic Salary (AED)</th>
                        <th>Accommodation (AED)</th>
                        <th>Transportation (AED)</th>
                        <th>Total Salary (AED)</th>
                        <th>From</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salaryDetails.length > 0 ? (
                        salaryDetails.map((salary, index) => (
                          <tr key={index}>
                            <td>{salary.basicS || '-'}</td>
                            <td>{salary.accommodation || '-'}</td>
                            <td>{salary.transportation || '-'}</td>
                            <td className="font-weight-bold text-success">{salary.totalS || '-'}</td>
                            <td>{salary.createdAt ? new Date(salary.createdAt).toLocaleDateString() : '-'}</td>
                            <td>{salary.updatedAt ? new Date(salary.updatedAt).toLocaleDateString() : '-'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-muted text-center py-4">
                            No salary details available for {selectedPerson.name}.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>



                {/* Footer */}
                <p className="text-muted mt-3">Showing {salaryDetails.length} records</p>
              </div>
            </div>
          );
        case 'Commissions':
          return (
            <div className="tab-contentt">
              <div className="section commissions-card">
                <div className="section-header d-flex justify-content-between align-items-center border-bottom pb-3 mb-4">
                  <h3 className="section-title text-primary font-weight-bold">
                    Employee Commissions
                  </h3>
                  <PlusCircle size={18} onClick={handleAddCommission} style={{ cursor: 'pointer' }} />
                </div>

                {commissions.length > 0 ? (
                  <div className="table-responsive">
                    <table className="commissions-table table table-hover table-bordered text-center">
                      <thead className="thead-dark">
                        <tr>
                          <th>Commission (AED)</th>
                          <th>Month/Year</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commissions.map((commission, index) => (
                          <tr key={index}>
                            <td className="font-weight-bold text-success">{commission.Commission || '-'}</td>
                            <td>{commission.monthYear}</td>
                            <td><Edit size={18} onClick={() => handleEditCommission(commission)} style={{ cursor: 'pointer' }} /></td>
                            <td><Trash size={18} onClick={() => handleDeleteCommission(commission.id)} style={{ cursor: 'pointer', color: 'red' }} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-muted text-center py-4">
                    No commission details available for {selectedPerson.name}.
                  </p>
                )}

                <p className="text-muted mt-3">Showing {commissions.length} records</p>
              </div>
            </div>
          );

        // case 'Off-Boarding':
        //   return (
        //     <div className="tab-contentt">
        //       <div className="section off-boarding-card">
        //         <div className="section-header">
        //           <h3 className="section-title">Termination</h3>
        //         </div>
        //         <div className="off-boarding-steps">
        //           <div className="step active">Off-Boarding (optional)</div>
        //           <div className="step">Termination</div>
        //         </div>
        //         <div className="form-group">
        //           <Label for="boarding-template">Boarding template</Label>
        //           <Input type="select" id="boarding-template" className="boarding-template">
        //             <option>Please select</option>
        //             {/* Add more options as needed */}
        //           </Input>
        //           <Button className="skip-button">Skip Off-Boarding</Button>
        //         </div>
        //       </div>
        //     </div>
        //   );
        // case 'Warning':
        //   return (
        //     <div className="tab-contentt">
        //       <div className="section warning-info-card">
        //         <div className="section-header">
        //           <h3 className="section-title">Warnings</h3>
        //           <Button color="primary" className="add-button" onClick={handleAddWarningDetail}>+ Add</Button>
        //         </div>
        //         <table className="warning-info-table">
        //           <thead>
        //             <tr>
        //               <th>Reason</th>
        //               <th>Management File</th>
        //               <th>Submission File</th>
        //               <th>Date</th>
        //               <th>Edit</th>
        //               <th>Delete</th>
        //             </tr>
        //           </thead>
        //           <tbody>
        //             {warnings.map((detail) => (
        //               <tr key={detail.id}>
        //                 <td>{detail.reason}</td>
        //                 <td>{detail.managementfile}</td>
        //                 <td>{detail.submitionfile}</td>
        //                 <td>{detail.Date}</td>
        //                 <td>
        //                   <Button color="primary" className="edit-button" onClick={() => handleEditWarningDetail(detail)}>Edit</Button>
        //                 </td>
        //                 <td>
        //                   <Button color="danger" onClick={() => handleDeleteWarningDetail(detail.id)}><Trash size={18} /></Button>
        //                 </td>
        //               </tr>
        //             ))}
        //           </tbody>
        //         </table>
        //         <p>Showing {warnings.length} results</p>
        //       </div>
        //     </div>
        //   );

        default:
          return null;
      }
    };

    return (
      <div className="person-details-card">
        <div className="card-per">
          <div className="card-header-per">
            <ArrowLeft className="back-icon" onClick={handleBackClick} />
            <h2 className="person-name">{selectedPerson.name}</h2>
            <Button color="danger" onClick={() => handleDeleteClick(selectedPerson)}><Trash size={18} /></Button>
          </div>
          <div className="tabs">
            {tabs.map((tab) => (
              <div
                key={tab} // Unique key for each tab
                className={`tab-item ${tab === activeTab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="card-body">
            {renderTabContent()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <style>
        {`
          .form-group.w-75 {
            display: none;
       
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }
 
          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3>Details</h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Col sm="12">
        {!selectedDepartment ? (
          <div className="department-container">
            <div className="department-grid">
              {[
                { name: "SALES", icon: "📊" },
                { name: "MARKETING", icon: "📢" },
                { name: "IT", icon: "💻" },
                { name: "DEALING", icon: "📈" },
                { name: "COMPLIANCE", icon: "⚖️" },
                { name: "FINANCE", icon: "🏦" },
                { name: "MANAGEMENT", icon: "🏢" },
                { name: "HR", icon: "👩‍💼" },
                { name: "SUPPORT", icon: "📁" }
              ].map(department => (
                <div
                  key={department.name}
                  className="department-card"
                  onClick={() => handleCardClick(department.name)}
                >
                  <div className="department-icon">{department.icon}</div>
                  <div className="department-name">{department.name}</div>
                </div>
              ))}
            </div>
          </div>
        ) : !selectedPerson ? (
          renderPersons(selectedDepartment)
        ) : (
          renderPersonDetails()
        )}
      </Col>


      {/* User Info Modal */}
      <UserInfoModal
        isOpen={userInfoModal}
        toggle={toggleUserInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Personal Info Modal */}
      <PersonalInfoModal
        isOpen={personalInfoModal}
        toggle={togglePersonalInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />


      {/* Contact Info Modal */}
      <ContactInfoModal
        isOpen={contactInfoModal}
        toggle={toggleContactInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/* Professional Info Modal */}
      <ProfessionalInfoModal
        isOpen={professionalInfoModal}
        toggle={toggleProfessionalInfoModal}
        formValues={formValues}
        handleFormChange={handleFormChange}
        handleSaveUserInfo={handleSaveUserInfo}
      />

      {/*  User Assets Modal */}
      <Modal isOpen={assetModal} toggle={toggleAssetModal}>
        <ModalHeader toggle={toggleAssetModal}>
          {isAssetsEditMode ? 'Edit Asset Info' : 'Add Asset Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveAsset}>
            <FormGroup>
              <Label for="asset_name">Asset Name</Label>
              <Input
                type="text"
                name="asset_name"
                id="asset_name"
                value={AssetsformValues.asset_name}
                onChange={handleAssetsFormChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="holding_date">Holding Date</Label>
              <Input
                type="date"
                name="holding_date"
                id="holding_date"
                value={AssetsformValues.holding_date}
                onChange={handleAssetsFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="dropping_date">Dropping Date</Label>
              <Input
                type="date"
                name="dropping_date"
                id="dropping_date"
                value={AssetsformValues.dropping_date}
                onChange={handleAssetsFormChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                value={AssetsformValues.notes}
                onChange={handleAssetsFormChange}
              />

            </FormGroup>
            <FormGroup>
              <Label for="createdby">Created By</Label>
              <Input
                type="text"
                name="createdby"
                id="createdby"
                value='H.R'
                onChange={handleFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="createdby">Updated By</Label>
              <Input
                type="text"
                name="updatedby"
                id="updatedby"
                value='H.R'
                onChange={handleFormChange}
                required
              />
            </FormGroup>

            <ModalFooter>
              <Button color="primary" type="submit">
                {isAssetsEditMode ? 'Update' : 'Save'}
              </Button>
              <Button color="secondary" onClick={toggleAssetModal}>
                Cancel
              </Button>
            </ModalFooter>

          </Form>
        </ModalBody>
      </Modal>

      {/* Add User Modal */}
      <AddUserModal isOpen={addUserModal} toggle={toggleAddUserModal} />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        personToDelete={personToDelete}
        confirmDelete={confirmDelete}
      />
      {/*Commission Modal*/}
      <Modal isOpen={commissionModal} toggle={() => setCommissionModal(false)}>
        <ModalHeader toggle={() => setCommissionModal(false)}>
          {isCommissionEditMode ? 'Edit Commission' : 'Add Commission'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="Commission">Commission Amount</Label>
              <Input type="number" name="Commission" id="Commission" value={commissionFormValues.Commission} onChange={(e) => setCommissionFormValues({ ...commissionFormValues, Commission: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="monthYear">Month/Year</Label>
              <Input type="select" name="monthYear" id="monthYear" value={commissionFormValues.monthYear} onChange={(e) => setCommissionFormValues({ ...commissionFormValues, monthYear: e.target.value })}>
                {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month, index) => (
                  [...Array(5).keys()].map(yearOffset => {
                    const year = new Date().getFullYear() - yearOffset;
                    return <option key={`${month}-${year}`} value={`${month} ${year}`}>{`${month} ${year}`}</option>;
                  })
                ))}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveCommission}>{isCommissionEditMode ? 'Update' : 'Add'}</Button>
          <Button color="secondary" onClick={() => setCommissionModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>


      {/* Bank Account Modal */}
      <Modal isOpen={bankAccountModal} toggle={toggleBankAccountModal}>
        <ModalHeader toggle={toggleBankAccountModal}>
          {isEditMode ? 'Edit Bank Account' : 'Add Bank Account'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="bank_name">Bank Name</Label>
            <Input
              type="text"
              name="bank_name"
              id="bank_name"
              value={bankAccountFormValues.bank_name}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="branch">Branch</Label>
            <Input
              type="text"
              name="branch"
              id="branch"
              value={bankAccountFormValues.branch}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="account_number">Account Number</Label>
            <Input
              type="text"
              name="account_number"
              id="account_number"
              value={bankAccountFormValues.account_number}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="iban">IBAN</Label>
            <Input
              type="text"
              name="iban"
              id="iban"
              value={bankAccountFormValues.iban}
              onChange={handleBankAccountFormChange}
            />
          </div>
          <div>
            <Label for="SWIFTcode">Transfer Percent</Label>
            <Input
              type="text"
              name="SWIFTcode"
              id="SWIFTcode"
              value={bankAccountFormValues.SWIFTcode}
              onChange={handleBankAccountFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveBankAccount}>
            {isEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleBankAccountModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      {/* Time Off Modal */}
      <Modal isOpen={timeOffModal} toggle={toggleTimeOffModal}>
        <ModalHeader toggle={toggleTimeOffModal}>
          {isEditMode ? 'Edit Time Off' : 'Add Time Off'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="timeoffType">Time Off Type</Label>
            <Input
              type="select"
              name="timeoffType"
              id="timeoffType"
              value={timeOffFormValues.timeoffType || ''} // Ensure default value is handled
              onChange={(e) =>
                handleTimeOffFormChange({
                  target: {
                    name: 'timeoffType',
                    value: e.target.value,
                  },
                })
              }
            >
              <option value="" disabled>
                Select Type
              </option>
              <option value="">Select Type</option> {/* Default placeholder option */}
              <option value="ANNUAL VACATIONS">ANNUAL VACATIONS</option>
              <option value="SICK DAY OFF">SICK DAY OFF</option>
              <option value="ABSENCE WITHOUT PERMISSIONS">ABSENCE WITHOUT PERMISSIONS</option>
              <option value="BUSINESS VACATION">BUSINESS VACATION</option>
              <option value="LATE DEDUCTION">LATE DEDUCTION</option>
              <option value="MANUAL ATTENDANCE">MANUAL ATTENDANCE</option>
              <option value="MATERNITY LEAVE">MATERNITY LEAVE</option>
              <option value="PATERNITY LEAVE">PATERNITY LEAVE</option>
              <option value="PERSONAL LEAVE">PERSONAL LEAVE</option>
              <option value="PILGRIMAGE LEAVE">PILGRIMAGE LEAVE</option>
              <option value="SICK LEAVE">SICK LEAVE</option>
              <option value="UNPAID VACATION">UNPAID VACATION</option>
              <option value="EARLY OUT">EARLY OUT</option>
              <option value="HALF DAY">HALF DAY</option>
            </Input>
          </div>

          <div>
            <Label for="customSetup">Custom Setup</Label>
            <Input
              type="select"
              name="customSetup"
              id="customSetup"
              value={timeOffFormValues.customSetup || ''} // Ensure default value is handled
              onChange={(e) =>
                handleTimeOffFormChange({
                  target: {
                    name: 'customSetup',
                    value: e.target.value,
                  },
                })
              }
            >
              <option value="" disabled>
                Select Option
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Input>
          </div>

          <div>
            <Label for="openBalance">Open Balance</Label>
            <Input
              type="number"
              name="openBalance"
              id="openBalance"
              value={timeOffFormValues.openBalance || ''} // Handle initial empty state
              onChange={(e) =>
                handleTimeOffFormChange({
                  target: {
                    name: 'openBalance',
                    value: e.target.value,
                  },
                })
              }
            />
          </div>

          <div>
            <Label for="carryBalance">Carry Balance</Label>
            <Input
              type="number"
              name="carryBalance"
              id="carryBalance"
              value={timeOffFormValues.carryBalance || ''} // Handle initial empty state
              onChange={(e) =>
                handleTimeOffFormChange({
                  target: {
                    name: 'carryBalance',
                    value: e.target.value,
                  },
                })
              }
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              const dataToSave = {
                ...timeOffFormValues,
                openBalance: `${timeOffFormValues.openBalance || 0} days`,
                carryBalance: `${timeOffFormValues.carryBalance || 0} days`,
              };
              handleSaveTimOff(dataToSave);
            }}
          >
            {isEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleTimeOffModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* Add Image Modal */}
      <Modal isOpen={addImgModal} toggle={() => setAddImgModal(false)}>
        <ModalHeader toggle={() => setAddImgModal(false)}>
          {'Add Employee Photo'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="img">Employee Photo</Label>
            <Input
              type="file"
              name="img"
              value={formValues.img}
              onChange={documentUpload}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveImg}>Save</Button>{' '}
          <Button color="secondary" onClick={() => toggleImgModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Emergency Contact Modal */}
      <Modal isOpen={familyDetailModal} toggle={() => setFamilyDetailModal(false)}>
        <ModalHeader toggle={() => setFamilyDetailModal(false)}>
          {isFamilyEditMode ? 'Edit Emergency Contact' : 'Add Emergency Contact'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="homeAddress">Home Country Address</Label>
              <Input type="text" name="homeAddress" id="homeAddress" value={familyFormValues.homeAddress} onChange={(e) => setFamilyFormValues({ ...familyFormValues, homeAddress: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="homeEcontactName">Home Country Emergency Contact Name/Relation</Label>
              <Input type="text" name="homeEcontactName" id="homeEcontactName" value={familyFormValues.homeEcontactName} onChange={(e) => setFamilyFormValues({ ...familyFormValues, homeEcontactName: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="homeEcontactNumber">Home Country Emergency Contact Number</Label>
              <Input type="text" name="homeEcontactNumber" id="homeEcontactNumber" value={familyFormValues.homeEcontactNumber} onChange={(e) => setFamilyFormValues({ ...familyFormValues, homeEcontactNumber: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="UAEaddress">UAE  Address</Label>
              <Input type="text" name="UAEaddress" id="UAEaddress" value={familyFormValues.UAEaddress} onChange={(e) => setFamilyFormValues({ ...familyFormValues, UAEaddress: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="UAEeContactName">UAE  Emergency Contact Name/Relation</Label>
              <Input type="text" name="UAEeContactName" id="UAEeContactName" value={familyFormValues.UAEeContactName} onChange={(e) => setFamilyFormValues({ ...familyFormValues, UAEeContactName: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="UAEeContactNumber">UAE Emergency Contact Number</Label>
              <Input type="text" name="UAEeContactNumber" id="UAEeContactNumber" value={familyFormValues.UAEeContactNumber} onChange={(e) => setFamilyFormValues({ ...familyFormValues, UAEeContactNumber: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="UAEpNumber">UAE Contact Number</Label>
              <Input type="text" name="UAEpNumber" id="UAEpNumber" value={familyFormValues.UAEpNumber} onChange={(e) => setFamilyFormValues({ ...familyFormValues, UAEpNumber: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="homePnumber">Home Country Contact Number</Label>
              <Input type="text" name="homePnumber" id="homePnumber" value={familyFormValues.homePnumber} onChange={(e) => setFamilyFormValues({ ...familyFormValues, homePnumber: e.target.value })} />
            </FormGroup>



          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveFamilyDetail}>
            {isFamilyEditMode ? 'Update' : 'Add'}
          </Button>
          <Button color="secondary" onClick={() => setFamilyDetailModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Insurance Modal */}
      <Modal isOpen={insuranceModal} toggle={toggleInsuranceModal}>
        <ModalHeader toggle={toggleInsuranceModal}>
          {isInsuranceEditMode ? 'Edit Insurance Detail' : 'Add Insurance Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="employeeName">Employee Name</Label>
            <Input
              type="text"
              name="employeeName"
              id="employeeName"
              value={insuranceFormValues.employeeName}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="insurerName">Insurer Name</Label>
            <Input
              type="text"
              name="insurerName"
              id="insurerName"
              value={insuranceFormValues.insurerName}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={insuranceFormValues.startDate}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={insuranceFormValues.endDate}
              onChange={handleInsuranceFormChange}
            />
          </div>
          <div>
            <Label for="deduction">Deduction</Label>
            <Input
              type="number"
              name="deduction"
              id="deduction"
              value={insuranceFormValues.deduction}
              onChange={handleInsuranceFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveInsuranceDetail}>
            {isInsuranceEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleInsuranceModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Ticket Allowance Modal */}
      <Modal isOpen={ticketAllowanceModal} toggle={toggleTicketAllowanceModal}>
        <ModalHeader toggle={toggleTicketAllowanceModal}>
          {isTicketAllowanceEditMode ? 'Edit Air Ticket Allowance' : 'Add Air Ticket Allowance'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="employeeName">Employee Name</Label>
            <Input
              type="text"
              name="employeeName"
              id="employeeName"
              value={ticketAllowanceFormValues.employeeName}
              onChange={handleTicketAllowanceFormChange}
            />
          </div>
          <div>
            <Label for="transactionType">Transaction Type</Label>
            <Input
              type="text"
              name="transactionType"
              id="transactionType"
              value={ticketAllowanceFormValues.transactionType}
              onChange={handleTicketAllowanceFormChange}
            />
          </div>
          <div>
            <Label for="date">Date</Label>
            <Input
              type="date"
              name="date"
              id="date"
              value={ticketAllowanceFormValues.date}
              onChange={handleTicketAllowanceFormChange}
            />
          </div>
          <div>
            <Label for="active">Active</Label>
            <Input
              type="checkbox"
              name="active"
              id="active"
              checked={new Date(ticketAllowanceFormValues.date) <= new Date()}
              disabled
            />

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveTicketAllowanceDetail}>
            {isTicketAllowanceEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={() => setTicketAllowanceModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      {/* Contract Modal */}
      <Modal isOpen={contractModal} toggle={toggleContractModal}>
        <ModalHeader toggle={toggleContractModal}>
          {isContractEditMode ? 'Edit Contract Detail' : 'Add Contract Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="contractName">Contract Name</Label>
            <Input
              type="text"
              name="contractName"
              id="contractName"
              value={contractFormValues.contractName}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={contractFormValues.startDate}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={contractFormValues.endDate}
              onChange={handleContractFormChange}
            />
          </div>
          <div>
            <Label for="contractType">Contract Type</Label>
            <Input
              type="select"
              name="contractType"
              id="contractType"
              value={contractFormValues.contractType}
              onChange={handleContractFormChange}
            >
              <option value="">Select Contract Type</option>
              <option value="Limited">Limited</option>
              <option value="Unlimited">Unlimited</option>
            </Input>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveContractDetail}>
            {isContractEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleContractModal}>Cancel</Button>
        </ModalFooter>
      </Modal>


      {/* Warning Modal */}
      <Modal isOpen={warningModal} toggle={toggleWarningModal}>
        <ModalHeader toggle={toggleWarningModal}>
          {isWarningEditMode ? 'Edit Warning Detail' : 'Add Warning Detail'}
        </ModalHeader>
        <ModalBody>
          <div>
            <Label for="reason">Reason</Label>
            <Input
              type="text"
              name="reason"
              id="reason"
              value={warningFormValues.reason}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="managementfile">Management File</Label>
            <Input
              type="text"
              name="managementfile"
              id="managementfile"
              value={warningFormValues.managementfile}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="submitionfile">Submission File</Label>
            <Input
              type="text"
              name="submitionfile"
              id="submitionfile"
              value={warningFormValues.submitionfile}
              onChange={handleWarningFormChange}
            />
          </div>
          <div>
            <Label for="Date">Date</Label>
            <Input
              type="date"
              name="Date"
              id="Date"
              value={warningFormValues.Date}
              onChange={handleWarningFormChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveWarningDetail}>
            {isWarningEditMode ? 'Update' : 'Add'}
          </Button>{' '}
          <Button color="secondary" onClick={toggleWarningModal}>Cancel</Button>
        </ModalFooter>
      </Modal>


    </Fragment>
  );
};

export default AdminEmployeeManagement;
