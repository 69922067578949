import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Table,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,

} from 'reactstrap';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";
import { VacationDays, addVacationDays, updateVacationDays, deleteVacationDays } from 'Services/vacationdays.service';
import { getUserOptions } from 'Services/user.service';
import classnames from 'classnames';

const AdminTimeManagment = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [modal, setModal] = useState(false);
  const [vacations, setvacations] = useState([]);
  const [vacationModal, setvacationModal] = useState(false);
  const [userOptions, setUserOptions] = useState([]); // Store user options
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [vacationSearch, setvacationSearch] = useState('');
  const [hourlyLeaveSearch, sethourlyLeaveSearch] = useState('');
  const [adjustmentSearch, setadjustmentSearch] = useState('');
  const [movedSearch, setmovedSearch] = useState('');
  // Toggle between tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // State for managing Vacation Days table data
  const [vacationFormValues, setvacationsFormValues] = useState([
    {
      empName: '',
      empNo: '',
      transactionType: '',
      fromDate: '',
      toDate: '',
      resumptionDate: '',
      noOfDays: '',
      status: '',
    },
    // Add more data here
  ]);
  const [isvactionEditMode, setIsvacationEditMode] = useState(false);
  // State for managing Hourly Leaves table data
  const [hourlyLeaveData, setHourlyLeaveData] = useState([
    {
      id: 1,
      empName: 'Ahmed Ibrahim',
      empNo: '100458',
      transactionType: 'Hourly Leave',
      fromDate: 'Thu, 2023-05-18',
      toDate: 'Thu, 2023-05-18',
      hours: '4 Hours',
      status: 'Approved by Manager',
    },
    // Add more data here
  ]);

  // State for managing Manage Adjustments table data
  const [adjustmentsData, setAdjustmentsData] = useState([
    {
      id: 1,
      empName: 'Alaa Mohamed Ismail Ahmed Mohamed',
      empNo: '101009',
      adjustmentName: 'Annual Vacations',
      amount: '-19.20 Days',
      effectiveDate: 'Wed, 2023-02-15',
    },
    {
      id: 2,
      empName: 'Atul Madaan',
      empNo: '100512',
      adjustmentName: 'Annual Vacations',
      amount: '6.00 Days',
      effectiveDate: 'Tue, 2023-08-15',
    },
    // Add more data here
  ]);
  const [MovedData, setMovedData] = useState([
    {
      id: 1,
      empName: 'Alaa Mohamed Ismail Ahmed Mohamed',
      empNo: '101009',
      vacationName: 'Annual Vacations',
      amount: '-19.20 Days',
      effectiveDate: 'Wed, 2023-02-15',
    },
    {
      id: 2,
      empName: 'Atul Madaan',
      empNo: '100512',
      vacationName: 'Annual Vacations',
      amount: '6.00 Days',
      effectiveDate: 'Tue, 2023-08-15',
    },
    // Add more data here
  ]);

  const loadVacationData = async () => {
    try {
      const fetchedvacations = await VacationDays();
      console.log("Fetched Vac", fetchedvacations);
      if (Array.isArray(fetchedvacations)) {
        setvacations(fetchedvacations);  // directly set the state with the array
      } else {
        console.error('Expected an array, but got:', fetchedvacations);
      }
    } catch (error) {
      console.error('Error fetching and filtering vacations:', error);
    }
  };
  
  const loadUserOptions = async () => {
    try {
      // Step 1: Fetch user options
      const response = await getUserOptions();
      console.log('API Response:', response);

      // Step 2: Check if response is valid
      if (!response || !response.data || !Array.isArray(response.data.data)) {
        console.error('No valid data in response:', response);
        return; // Exit if response or data is invalid
      }

      // Step 3: Access the actual user data array
      const userDataArray = response.data.data; // Access the nested 'data' array
      console.log('User Data Array:', userDataArray);

      // Step 4: Proceed with formatting options
      const formattedOptions = userDataArray.map(user => {
        // Check for required properties
        if (user.id && user.firstName && user.lastName) {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`
          };
        } else {
          console.error('User object is missing properties:', user);
          return null; // Handle missing properties
        }
      }).filter(option => option !== null); // Remove nulls if any

      console.log('Formatted Options:', formattedOptions);
      setUserOptions(formattedOptions); // Set formatted options
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  useEffect(() => {
    console.log('Vacations:', vacations);
    loadVacationData();
    loadUserOptions(); // Fetch users on component load
  }, []);
  const togglevacationModal = () => setvacationModal(!vacationModal);
  // Toggle modal visibility


  const handleVacationFormChange = (e, id) => {
    const { name, value } = e.target;

    const updatedVacation = [...vacationFormValues];
   
    if (name === 'empName') {
      const selectedEmployee = userOptions.find(emp => `${emp.label}` === value);

      updatedVacation[id] = {
        ...updatedVacation[id],
        empName: value, // Set employee name
        empNo: selectedEmployee ? selectedEmployee.value : '' // Auto-fill employee number
      };
    } else {
      updatedVacation[id] = { ...updatedVacation[id], [name]: value }; // Regular field update
    }
    

    setvacationsFormValues(updatedVacation);
  };

  const initializeCreateVacationForm = () => {
    setIsvacationEditMode(false);
    setvacationsFormValues([{
      empName: '',
      empNo: '',
      transactionType: '',
      fromDate: '',
      toDate: '',
      resumptionDate: '',
      noOfDays: '',
      status: '',
    }]);
    togglevacationModal();
  };

  const initializeUpdateVacationForm = (task) => {
    setvacationsFormValues([task]);
    setIsvacationEditMode(true);
    togglevacationModal();
  };
  const deleteVacation = async (id) => {
    try {
      await deleteVacationDays(id);
      toast.success('Vacation deleted successfully!');
      setvacations((prevDetails) => prevDetails.filter((vacation) => vacation.id !== id));
    } catch (error) {
      console.error('Error deleting vacation:', error);
      toast.error('Failed to delete vacation. Please try again.');
    }
  };

  const handleSaveVacation = async (e) => {
    e.preventDefault();
    try {
      if (isvactionEditMode) {
        await updateVacationDays(vacationFormValues[0].id, vacationFormValues[0]);
        toast.success('Task updated successfully!');
      } else {
        await addVacationDays(vacationFormValues[0]);
        toast.success('Vacation added successfully!');
      }
      await loadVacationData();
      togglevacationModal();
    } catch (error) {
      console.error('Error saving vacation:', error);
      toast.error('Failed to save vacation. Please try again.');
    }
  };

  const handleSearchVacationChange = (e) => {
    setvacationSearch(e.target.value);
  };


  const filteredvacations = vacations.filter((vacation) =>
    vacation.empName.toLowerCase().includes(vacationSearch.toLowerCase()) ||
    vacation.empNo.toLowerCase().includes(vacationSearch.toLowerCase())

  );
  console.log ("Filtered Vac" , filteredvacations);

  const rendervacationModal = () => (
    <Modal isOpen={vacationModal} toggle={togglevacationModal}>
      <ModalHeader toggle={togglevacationModal}>
        {isvactionEditMode ? 'Edit Vacation Info' : 'Add Vacation Info'}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSaveVacation}>
          {vacationFormValues.map((vacation, index) => (
            <div key={vacation.id || index}> {/* Use unique ID if available */}
              {/* Employee Name - Dropdown */}
              <FormGroup>
                <Label for="empName">Employee Name</Label>
                <Input
                  type="select"
                  name="empName"
                  id="empName"
                  value={vacationFormValues[0].empName}
                  onChange={(e) => handleVacationFormChange(e, 0)}
                >
                  <option value="">Select Employee</option>
                  {userOptions.map(user => (
                    <option key={user.value} value={user.label}>
                      {user.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="empNo">Employee Number</Label>
                <Input
                  type="text"
                  name="empNo"
                  id="empNo"
                  value={vacationFormValues[0].empNo}
                  onChange={(e) => handleVacationFormChange(e, 0)}
                  disabled // Disable this field as it auto-fills based on employee selection
                />
              </FormGroup>


              {/* Assigned At */}
              <FormGroup>
                <Label for={`fromDate_${index}`}>From Date</Label>
                <Input
                  type="date"
                  name="fromDate"
                  id={`fromDate_${index}`}
                  value={vacation.fromDate || ''}
                  onChange={(e) => handleVacationFormChange(e, index)}
                />
              </FormGroup>
              <FormGroup>
                <Label for={`toDate_${index}`}>To Date</Label>
                <Input
                  type="date"
                  name="toDate"
                  id={`toDate_${index}`}
                  value={vacation.toDate || ''}
                  onChange={(e) => handleVacationFormChange(e, index)}
                />
              </FormGroup>
              <FormGroup>
                <Label for={`resumptionDate_${index}`}>Resumption Date</Label>
                <Input
                  type="date"
                  name="resumptionDate"
                  id={`resumptionDate_${index}`}
                  value={
                    vacation.toDate
                      ? new Date(new Date(vacation.toDate).getTime() + 24 * 60 * 60 * 1000)
                        .toISOString()
                        .split('T')[0]
                      : ''
                  }
                  readOnly
                />
              </FormGroup>
              <FormGroup>
                <Label for={`noOfDays_${index}`}>Number Of Days</Label>
                <Input
                  type="number"
                  name="noOfDays"
                  id={`noOfDays_${index}`}
                  value={
                    vacation.fromDate && vacation.toDate
                      ? Math.ceil(
                        (new Date(vacation.toDate) - new Date(vacation.fromDate)) /
                        (1000 * 60 * 60 * 24)
                      )
                      : 0
                  }
                  readOnly
                />
              </FormGroup>

              {/* Task Type */}
              <FormGroup>
                <Label for={`transactionType_${index}`}>Transaction Type</Label>
                <Input
                  type="select"
                  name="transactionType"
                  id={`transactionType_${index}`}
                  value={vacation.transactionType || ''}
                  onChange={(e) => handleVacationFormChange(e, index)}
                  required
                >
                  <option value="">Select Type</option> {/* Default placeholder option */}
                  <option value="ANNUAL VACATIONS">ANNUAL VACATIONS</option>
                  <option value="SICK DAY OFF">SICK DAY OFF</option>
                  <option value="ABSENCE WITHOUT PERMISSIONS">ABSENCE WITHOUT PERMISSIONS</option>
                  <option value="BUSINESS VACATION">BUSINESS VACATION</option>
                  <option value="LATE DEDUCTION">LATE DEDUCTION</option>
                  <option value="MANUAL ATTENDANCE">MANUAL ATTENDANCE</option>
                  <option value="MATERNITY LEAVE">MATERNITY LEAVE</option>
                  <option value="PATERNITY LEAVE">PATERNITY LEAVE</option>
                  <option value="PERSONAL LEAVE">PERSONAL LEAVE</option>
                  <option value="PILGRIMAGE LEAVE">PILGRIMAGE LEAVE</option>
                  <option value="SICK LEAVE">SICK LEAVE</option>
                  <option value="UNPAID VACATION">UNPAID VACATION</option>
                  <option value="EARLY OUT">EARLY OUT</option>
                  <option value="HALF DAY">HALF DAY</option>

                </Input>
              </FormGroup>

              {/* Status */}
              <FormGroup>
                <Label for={`status_${index}`}>Status</Label>
                <Input
                  type="select"
                  name="status"
                  id={`status_${index}`}
                  value={vacation.status || 'Pending'}
                  onChange={(e) => handleVacationFormChange(e, index)}
                  required
                >
                  <option value="Pending">Pending</option>
                  <option value="In_Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Canceled">Canceled</option>
                </Input>
              </FormGroup>

            </div>
          ))}
          <ModalFooter>
            <Button color="primary" type="submit">
              {isvactionEditMode ? 'Update' : 'Save'}
            </Button>
            <Button color="secondary" onClick={togglevacationModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
  const toggleModal = (leave = null) => {
    setSelectedLeave(leave);
    setModal(!modal);
  };

  // Modal content render logic
  const renderModal = () => {
    if (!selectedLeave) return null;

    return (
      <Modal isOpen={modal} toggle={() => toggleModal()}>
        <ModalHeader toggle={() => toggleModal()}>Leave Details</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedLeave.empName}</p>

          <Label>Transaction Type: </Label>
          <p>{selectedLeave.transactionType}</p>

          <Label>From Date: </Label>
          <p>{selectedLeave.fromDate}</p>

          <Label>To Date: </Label>
          <p>{selectedLeave.toDate}</p>

          {selectedLeave.noOfDays ? (
            <>
              <Label>No. of Days: </Label>
              <p>{selectedLeave.noOfDays}</p>
            </>
          ) : (
            <>
              <Label>Hours: </Label>
              <p>{selectedLeave.hours}</p>
            </>
          )}

          <Label>Status: </Label>
          <p>{selectedLeave.status}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const filteredhourlyLeave = hourlyLeaveData.filter((hourlyLeave) =>
    hourlyLeave.empName.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.empNo.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.transactionType.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.fromDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.toDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.resumptionDate.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.hours.toLowerCase().includes(hourlyLeaveSearch.toLowerCase()) ||
    hourlyLeave.status.toLowerCase().includes(hourlyLeaveSearch.toLowerCase())
  );
  const filteredadjustments = adjustmentsData.filter((adjustment) =>
    adjustment.empName.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
    adjustment.empNo.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
    adjustment.adjustmentName.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
    adjustment.amount.toLowerCase().includes(adjustmentSearch.toLowerCase()) ||
    adjustment.effectiveDate.toLowerCase().includes(adjustmentSearch.toLowerCase())

  );
  const filteredbalances = MovedData.filter((moved) =>
    moved.empName.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.empNo.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.vacationName.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.amount.toLowerCase().includes(movedSearch.toLowerCase()) ||
    moved.effectiveDate.toLowerCase().includes(movedSearch.toLowerCase())

  );
  return (
    <div className="container-fluid">
        <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>
      {/* Breadcrumb and Page Title */}
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs for switching between Vacation Days, Hourly Leaves, and Manage Adjustments */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('1')}
            style={{
              cursor: 'pointer',
              color: activeTab === '1' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Vacation Days
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{
              cursor: 'pointer',
              color: activeTab === '2' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Hourly Leaves
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{
              cursor: 'pointer',
              color: activeTab === '3' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Manage Adjustments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggleTab('4')}
            style={{
              cursor: 'pointer',
              color: activeTab === '4' ? '#194995' : 'black',  // Apply #194995 color when active
            }}
          >
            Manage Moved Balances
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Vacation Days Tab */}
        {/* Vacation Days Tab */}
        <TabPane tabId="1">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Vacation Days</h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search Vacation Days"
                  value={vacationSearch}
                  onChange={handleSearchVacationChange}
                  style={{ width: '50%' }} // Adjust width to make it responsive
                />
                <PlusCircle size={18} onClick={initializeCreateVacationForm} style={{ cursor: 'pointer' }} />

              </div>

            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Transaction Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Resumption Date</th>
                    <th>No. of Days</th>
                    <th>Status</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  filteredvacations.map((vacation) => (
                    <tr key={vacation.id}>
                      <td>{vacation.empName}</td>
                      <td>{vacation.empNo}</td>
                      <td>{vacation.transactionType}</td>
                      <td>{vacation.fromDate}</td>
                      <td>{vacation.toDate}</td>
                      <td>{vacation.resumptionDate}</td>
                      <td>{vacation.noOfDays}</td>
                      <td>{vacation.status}</td>
                      <td>
                       
                        <Edit size={18} onClick={() => initializeUpdateVacationForm(vacation)} style={{ cursor: 'pointer' }} />

                      </td>
                      <td>
                        
                        <Trash size={18} onClick={() => deleteVacation(vacation.id)} style={{ cursor: 'pointer', color: 'red' }} />

                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>

        {/* Hourly Leaves Tab */}
        <TabPane tabId="2">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Hourly Leaves</h3>
              <Input
                type="text"
                placeholder="Search Hourly Leaves"
                value={hourlyLeaveSearch}
                onChange={(e) => sethourlyLeaveSearch(e.target.value)}
                style={{ width: '60%' }} // Adjust width to make it responsive
              />
            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Transaction Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Hours</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredhourlyLeave.map((leave) => (
                    <tr key={leave.id}>
                      <td>{leave.empName}</td>
                      <td>{leave.empNo}</td>
                      <td>{leave.transactionType}</td>
                      <td>{leave.fromDate}</td>
                      <td>{leave.toDate}</td>
                      <td>{leave.hours}</td>
                      <td>{leave.status}</td>
                      <td>
                        <Button color="primary" onClick={() => toggleModal(leave)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>

        {/* Manage Adjustments Tab */}
        <TabPane tabId="3">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Manage Adjustments</h3>
              <Input
                type="text"
                placeholder="Search Manage Adjustments"
                value={adjustmentSearch}
                onChange={(e) => setadjustmentSearch(e.target.value)}
                style={{ width: '60%' }} // Adjust width to make it responsive
              />
            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Adjustment Name</th>
                    <th>Amount</th>
                    <th>Effective Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredadjustments.map((adjustment) => (
                    <tr key={adjustment.id}>
                      <td>{adjustment.empName}</td>
                      <td>{adjustment.empNo}</td>
                      <td>{adjustment.adjustmentName}</td>
                      <td>{adjustment.amount}</td>
                      <td>{adjustment.effectiveDate}</td>
                      <td>
                        <Button color="primary" onClick={() => toggleModal(adjustment)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>

        {/* Manage Moved Balances Tab */}
        <TabPane tabId="4">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>Manage Moved Balances</h3>
              <Input
                type="text"
                placeholder="Search Manage Moved Balances"
                value={movedSearch}
                onChange={(e) => setmovedSearch(e.target.value)}
                style={{ width: '60%' }} // Adjust width to make it responsive
              />
            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Leave/Vacation Name</th>
                    <th>Amount</th>
                    <th>Effective Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredbalances.map((mBalances) => (
                    <tr key={mBalances.id}>
                      <td>{mBalances.empName}</td>
                      <td>{mBalances.empNo}</td>
                      <td>{mBalances.vacationName}</td>
                      <td>{mBalances.amount}</td>
                      <td>{mBalances.effectiveDate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>

      </TabContent>

      {renderModal()}
      {rendervacationModal()}
    </div>
  );
};

export default AdminTimeManagment;
